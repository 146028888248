import { useState } from 'react';
import { Description, DescriptionWrapper, Heading, ViewMoreBtn, Wrapper } from './styled';

interface Props {
  heading: string;
  description: string;
  isCompanypage?: boolean;
  topSpace?: boolean;
  isCategoryPage?: boolean;
  isMobileView?: boolean;
}

const HeaderSection = ({
  heading,
  description,
  isCompanypage,
  topSpace,
  isCategoryPage,
  isMobileView,
}: Props) => {
  const VIEW_TEXT = {
    more: 'View More',
    less: 'View Less',
  };
  const [viewMore, setViewMore] = useState(VIEW_TEXT.more);
  const [descriptionShort, setDescriptionShort] = useState(
    description.slice(0, 144) + ' ...'
  );
  const handleViewMore = () => {
    if (viewMore == VIEW_TEXT.less) {
      setViewMore(VIEW_TEXT.more);
      setDescriptionShort(description.slice(0, 144) + ' ...');
    }
    if (viewMore == VIEW_TEXT.more) {
      setViewMore(VIEW_TEXT.less);
      setDescriptionShort(description);
    }
  };
  return (
    <Wrapper isCompanypage={isCompanypage} topSpace={topSpace}>
      {isCategoryPage ? (
        <>
          {description && (
            <>
              <DescriptionWrapper>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: isMobileView ? descriptionShort : description,
                  }}
                  isCategoryPage={isCategoryPage}
                />
                {isMobileView && (
                  <ViewMoreBtn href="#" onClick={handleViewMore} isTextMore = {viewMore == VIEW_TEXT.more}>
                    {viewMore}
                  </ViewMoreBtn>
                )}
              </DescriptionWrapper>
            </>
          )}
          {heading && (
            <Heading
              dangerouslySetInnerHTML={{ __html: heading }}
              isCategoryPage={isCategoryPage}
            ></Heading>
          )}
        </>
      ) : (
        <>
          {heading && (
            <Heading dangerouslySetInnerHTML={{ __html: heading }}></Heading>
          )}
          {description && (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default HeaderSection;
