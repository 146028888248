import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

interface ButtonProps {
  bgColor?: string;
  boxShadow?: string;
  position?: string;
  className?: string;
}

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  padding: 0.6rem 2.7rem 0.6rem 0.9rem;
  border-radius: 0.4rem;
  margin: 0 0.4rem 0 0;
  position: relative;

  &::after {
    content: '';
    border-top: 0.4rem solid;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    display: flex;
    margin-left: 0.3rem;
    ${Mixin.Position({
      value: 'auto 1rem auto auto',
    })}
  }

  ${Mixin.VerticalCenter()};
  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      font-weight: ${FilterBtnStyle.FontWeights.iconFWNormal};
      background-color: ${FilterBtnStyle.BgColors.toggleBtn};
      border: 0.1rem solid ${FilterBtnStyle.BdrColors.toggleBtn};
      font-size: ${FilterBtnStyle.FontSizes.toggleBtn};
      color: ${FilterBtnStyle.Colors.toggleBtn};
      line-height: ${FilterBtnStyle.LineHeight.toggleBtn};

      &.active {
        color: ${FilterBtnStyle.Colors.toggleBtnActive};
        background: ${FilterBtnStyle.BgColors.toggleBtnActive};
        box-shadow: inset 0 0.3rem 0.5rem
          ${FilterBtnStyle.BdrColors.filterBtnShadow};
        padding-right: 3.7rem;
      }

      &.active::after {
        content: '\f05c';
        border: none;
        font-family: ${FilterBtnStyle.FontFamilies.iconFontFamily};
        ${Mixin.Position({ value: '0 0 0 auto' })}
        ${Mixin.AllCenter()};
        padding: 0 0.6rem;
        font-size: ${FilterBtnStyle.FontSizes.iconFontSize};
        box-shadow: inset 0 0.3rem 0.5rem
          ${FilterBtnStyle.BdrColors.filterBtnShadow};
      }
    `}
`;

export const FilterCount = styled.span`
  margin-left: 0.3rem;
`;

export const DropdownList = styled.div`
  max-height: 30rem;
  overflow: auto;
  border-radius: 0.4rem;
  min-width: 16rem;
  width: max-content;
  padding: 1.5rem;
  z-index: 1;
  ${Mixin.Position({ value: `102% auto auto auto` })}

  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      background-color: ${FilterBtnStyle.BgColors.dropdownListWrap};
      border: 0.1rem solid ${FilterBtnStyle.BdrColors.dropdownListWrap};
    `}
`;

export const DropCatList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      font-size: ${FilterBtnStyle.FontSizes.dropdownList};
      line-height: ${FilterBtnStyle.LineHeight.dropdownList};
    `}
`;

export const DropCatLink = styled.div<{ isFilterFlyer?: boolean }>`
  display: flex;
  padding: 0.4rem 0;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
  ${({ theme: { FilterBtnStyle }, isFilterFlyer }) =>
    FilterBtnStyle &&
    css`
      color: ${FilterBtnStyle.Colors.link};

      &:hover {
        color: ${FilterBtnStyle.Colors.linkHover};
        background-color: ${FilterBtnStyle.BgColors.linkHover};
      }
      ${isFilterFlyer &&
      css`
        font-size: ${FilterBtnStyle.FontSizes.flyercatlist};
      `}
    `}
`;

export const DropCatListItem = styled.li`
  ul {
    padding-left: 1.6rem;
  }
`;

export const Checkbox = styled.input`
  margin: 0 0.7rem 0 0;
`;

export const BtnGroup = styled.div<{ isRCPortal?: boolean }>`
  position: relative;

  .filter-group {
    font-weight: 400;
    padding: 0.7rem 2.4rem 0.7rem 1.2rem;
    min-width: 11rem;
    display: inline-flex;
    align-items: center;
    ${(isRCPortal) =>
      isRCPortal
        ? css`
            height: 3.5rem;
            min-width: 9.1rem;
          `
        : css`
            height: 3.73rem;
          `}

    ${({ theme: { FilterBtnStyle } }) =>
      FilterBtnStyle &&
      css`
        border-color: ${FilterBtnStyle.BdrColors.filterBdrAda};
        font-size: ${FilterBtnStyle.FontSizes.dropdownList};
        color: ${FilterBtnStyle.Colors.filterText};
        line-height: 1;
        &::placeholder {
          color: ${FilterBtnStyle.Colors.filterPlaceholder};
        }
      `}
    &.no-category {
      &::after {
        content: '';
        ${(isRCPortal) =>
          isRCPortal
            ? css`
                border-top: 0.5rem solid;
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
              `
            : css`
                border-top: 0.4rem solid;
                border-left: 0.4rem solid transparent;
                border-right: 0.4rem solid transparent;
              `}

        display: flex;
        ${Mixin.Position({ value: 'auto 1rem auto auto' })}
      }
    }

    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline-offset: 0.2rem;
    }
  }
`;

export const IconWrap = styled.button<{ isRCPortal?: boolean }>`
  padding: 1rem 0.6rem;
  cursor: pointer;
  line-height: 2.2;

  .cross-icon {
    font-weight: 400;
  }
  ${({ theme: { FilterBtnStyle }, isRCPortal }) =>
    FilterBtnStyle &&
    css`
      box-shadow: ${isRCPortal
        ? 'none'
        : `inset 0 0.3rem 0.5rem
        ${FilterBtnStyle.BdrColors.filterBtnShadow}`};
      color: ${FilterBtnStyle.Colors.selectedCrossIcon};
    `}
  &:focus {
    outline-offset: 0.2rem;
  }
`;

export const BtnWrap = styled.div<{ isRCPortal?: boolean }>`
  position: relative;
  ${({ theme: { FilterBtnStyle }, isRCPortal }) =>
    FilterBtnStyle &&
    css`
      &.active {
        color: ${FilterBtnStyle.Colors.toggleBtnActive};
        background: ${FilterBtnStyle.BgColors.toggleBtnActive};
        box-shadow: ${isRCPortal
          ? 'none'
          : `inset 0 0.3rem 0.5rem
          ${FilterBtnStyle.BdrColors.filterBtnShadow}`};
        border-radius: 0.4rem;

        ${Mixin.AllCenter()}
        .filter-group {
          background: ${FilterBtnStyle.BgColors.toggleBtnActive};
          border: none;
          color: ${FilterBtnStyle.Colors.toggleBtnActive};
          &:focus {
            outline-offset: 0.2rem;
          }
        }
      }
    `}
`;

export const CrossIcon = styled.span<{ isRCPortal?: boolean }>`
  width: 1.6rem;
  height: 1.6rem;
  border: 0.2rem solid;
  border-radius: 50%;
  line-height: ${({ isRCPortal }) => (isRCPortal ? '1.5rem' : '1.2rem')};
  ${Mixin.AllCenter()}
  text-align: justify;

  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      color: ${FilterBtnStyle?.Colors?.crossIcon};
      font-size: ${FilterBtnStyle.FontSizes.crossIcon};
      font-weight: ${FilterBtnStyle.FontWeights.crossIcon};
    `}
`;
