export const jobSearchFilters: { [key: string]: string } = {
  excludeWords: 'excludeWords',
  candidateAnywhereInUS: 'candidateAnywhereInUS',
  age: 'age',
  usLocation: 'usLocation',
  candidateAnywhereInWorld: 'candidateAnywhereInWorld',
  internationalLocation: 'internationalLocation',
  searchKeyword: 'searchkeyword',
  location: 'location',
  remoteoptions: 'remoteoptions',
  jobtypes: 'jobtypes',
  jobschedules: 'jobschedules',
  careerlevel: 'careerlevel',
  educations: 'educations',
  jobtitles: 'jobtitles',
  companies: 'companies',
  accolades: 'accolades',
  travels: 'travels',
  categories: 'categories',
  salaryrange: 'salaryrange',
  joblocations: 'joblocations',
  jobbenefits: 'jobbenefits',
};
