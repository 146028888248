import MobilePremium from '@components/jobCard/Mobile/mobilePremium';
import MobileVisitor from '@components/jobCard/Mobile/mobileVisitor';
import { IJobCardDeviceProps } from '@components/shared.types';

const MobileJobCard = (props: IJobCardDeviceProps) => {
  if (props.isFreeJob || props.isPremiumUser) {
    //Mobile Premium
    return <MobilePremium {...props} />;
  } else {
    //Mobile Visitor
    return <MobileVisitor {...props} />;
  }
};

export default MobileJobCard;
