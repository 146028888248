import MobilePremium from '@components/jobDetails/Mobile/mobilePremium';
import MobileVisitor from '@components/jobDetails/Mobile/mobileVisitor';
import { IJobDetailsDeviceProps } from '@components/shared.types';

const MobileJobDetails = (props: IJobDetailsDeviceProps) => {
  if (!props.isPremiumUser) {
    //Desktop Visitor
    return <MobileVisitor {...props} />;
  } else {
    //Desktop Premium
    return <MobilePremium {...props} />;
  }
};

export default MobileJobDetails;
