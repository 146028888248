import { Action, ClickOption } from 'helper/eventTrackingUtils/constant';
import { Children, useEffect, useState } from 'react';
import { jobSearchResultsInteractions } from '../event-tracker';
import { CloseButton, SecWrap, TipContents, ToolTipBtn } from './styled';

interface Props {
  children?: React.ReactNode;
  color?: string;
  bgColor?: string;
  tooltipWidth?: string;
  arrowPosition?: string;
  role?: string;
  hideContent?: any;
  hideCloseBtn?: any;
}

const ToolTipSection = (props: Props) => {
  const [TipBtn, TipContent] = Children.toArray(props.children);
  const [isToolTip, setToolTip] = useState(false);

  const hideToolTip = () => {
    setToolTip(false);
  };
  const ToolToggleTips = (event: any) => {
    props.hideContent && props.hideContent();
    setToolTip((prevValue) => {
      if (!prevValue)
        jobSearchResultsInteractions(Action.Clicked, ClickOption.Tips);

      return !prevValue;
    });
    event?.stopPropagation();
  };
  useEffect(() => {
    document.querySelectorAll('body')[0].addEventListener('click', hideToolTip);
    // Event listener for keydown
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        hideToolTip();
      }
    });
  }, []);

  return (
    <SecWrap>
      <ToolTipBtn onClick={(event) => ToolToggleTips(event)}>
        {TipBtn}
      </ToolTipBtn>
      {isToolTip && TipContent && (
        <TipContents {...props}>
          {' '}
          {props.hideCloseBtn ? (
            ''
          ) : (
            <CloseButton onClick={() => setToolTip(false)} />
          )}
          {TipContent}
        </TipContents>
      )}
    </SecWrap>
  );
};

export default ToolTipSection;
