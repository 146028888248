import {
  checkIsFJFeatureAllowed,
  getCompleteWordSubstring,
} from '@components/common';
import Breadcrumb from '@components/common/breadcrumb';
import {
  CATEGORY_ICON_SLUG,
  countryTitlesArr,
  readValueUsingKey,
} from '@components/common/constant';
import { jobSearchResultsInteractions } from '@components/common/event-tracker';
import { Icon, Tab, TabLink, TabsWrapper } from '@components/common/styled';
import {
  ArrowIcon,
  ReadToggle,
} from '@components/companies/overviewSuccess/styled';
import SuccessStorys from '@components/companies/successstory';
import {
  CategoryProps,
  JobDetailLocalization,
  SubCategories,
  SuccessStory,
} from '@components/shared.types';
import { Action, ClickOption } from 'helper/eventTrackingUtils/constant';
import { useState } from 'react';
import { COMPANY_TABS, LOCATION_TABS } from './constants';
import {
  BreadcrumbWrap,
  DescWrap,
  FAQWrap,
  OverviewWrap,
  PageHead,
} from './styled';

interface Props {
  categoryDetails: CategoryProps;
  categorySlug: string;
  getFaqData: Function;
  localization: JobDetailLocalization;
  subCategories?: SubCategories;
  faqData: any;
  categorySuccessStories?: [SuccessStory];
  isPremiumUser: boolean;
  mobileView?: boolean;
  isForCategoryWithLocation?: boolean;
  isForLocation?: boolean;
  pageLocation?: string;
  breadcrumbDropdown: any;
  tabsToHide?: string[];
  isLoggedIn?: boolean;
  isRCPortal?: boolean;
}

const BREADCRUMB_MAP = {
  category: {
    premium: 'jobs-category-page-premium',
    public: 'jobs-category-page-public',
  },
  subCategory: {
    premium: 'jobs-subCategory-page-premium',
    public: 'jobs-subCategory-page-public',
  },
};

const breadCrumbKey = (isPremiumUser: boolean, isSubCategory: boolean) => {
  let links: { premium: string; public: string } = BREADCRUMB_MAP.category;
  if (isSubCategory) {
    links = BREADCRUMB_MAP.subCategory;
  }

  return isPremiumUser ? links.premium : links.public;
};
const makeDynamicValues = (categoryOBJ: any) => {
  let arr = [];
  if (categoryOBJ?.isSubCategory) {
    arr.push(categoryOBJ.parentCategory.name);
    arr.push(categoryOBJ.parentCategory.slug);
  }
  return arr;
};

const CategoryDetails = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('overview');

  const { categoryTitle, categoryDescription } = props.categoryDetails;

  const handleTabClick = (activeId: string) => {
    setActiveTab(activeId);
    activeId == 'successstories' &&
      jobSearchResultsInteractions(Action.Clicked, ClickOption.Success_Stories);
  };

  let isForFJ = checkIsFJFeatureAllowed();

  let overviewText = categoryDescription;
  if (isForFJ) {
    overviewText = categoryDescription
      ? `${
          props?.localization?.categories_text?.body_prefix?.replace(
            /\{0\}/g,
            categoryTitle.toLowerCase()
          ) || ''
        } ${categoryDescription}`
      : '';
  }

  const titleText = `${
    readValueUsingKey(countryTitlesArr, categoryTitle) || categoryTitle
  } ${props.localization?.categories_text?.heading_suffix || ''}`;

  const getOverviewTextShort = (
    fullText: string | null,
    pageLocation: string | undefined,
    categoryTitle?: string | undefined
  ) => {
    if (pageLocation === 'acmgm') {
      const shortTextLen = 250 + 68 + (categoryTitle?.length ?? 0);
      let shortText = getCompleteWordSubstring(fullText, shortTextLen);
      if (shortText?.includes('<p>')) {
        shortText = shortText.replace('<p>', '</p><p>');
        return `<p>${shortText}`;
      } else {
        return `<span>${shortText}... </span>`;
      }
    } else if (pageLocation === 'locationPage') {
      return getCompleteWordSubstring(fullText, 241) + '... ';
    }
    return fullText;
  };

  const getOverviewTextFull = (
    overviewText: string | null,
    pageLocation: string | undefined
  ) => {
    if (pageLocation === 'acmgm') {
      let fullText = overviewText?.replace('<p>', '</p><p>');
      return `<p>${fullText}`;
    }
    return overviewText;
  };

  const overviewTextShort = getOverviewTextShort(
    overviewText,
    props.pageLocation,
    props.categoryDetails?.categoryTitle
  );

  const overviewTextFull = getOverviewTextFull(
    overviewText,
    props.pageLocation
  );

  let visibleOverViewText = overviewTextFull;
  if (isForFJ) {
    visibleOverViewText =
      isExpanded || (overviewTextFull && overviewTextFull?.length <= 237)
        ? overviewTextFull
        : overviewTextShort ?? '';
  }

  let tabs = !isForFJ ? [] : props.isForLocation ? LOCATION_TABS : COMPANY_TABS;
  if (Array.isArray(props.tabsToHide) && props.tabsToHide.length > 0) {
    tabs = tabs.filter((tab) => !props.tabsToHide?.includes(tab.id));
  }
  const breadCrumbwrap = () => {
    return (
      <BreadcrumbWrap>
        <Breadcrumb
          page={breadCrumbKey(
            props.isPremiumUser,
            CATEGORY_ICON_SLUG[props?.categoryDetails?.slug]?.isSubCategory
          )}
          dynamicValues={[
            props.categoryDetails.categoryTitle,
            ...makeDynamicValues(
              CATEGORY_ICON_SLUG[props?.categoryDetails?.slug]
            ),
          ]}
          categoryDropdown={props.subCategories}
          moreDropdownText="More Categories"
          showCategoryDropdown={
            props.subCategories?.relatedCategory?.length ||
            props.subCategories?.subCategory?.length
              ? true
              : false
          }
        />
      </BreadcrumbWrap>
    );
  };
  return (
    <>
      {props.mobileView ||
      props.isForLocation ||
      props.isForCategoryWithLocation ? (
        <></>
      ) : (
        <>
          {props.isRCPortal && breadCrumbwrap()}
          {!props.isRCPortal && breadCrumbwrap()}
        </>
      )}
      {isForFJ && !props.isForCategoryWithLocation && (
        <PageHead
          pageLocation={props.pageLocation}
          imagePath={`${
            process.env.NEXT_PUBLIC_CONTENT_URL
          }/images/icons/streamline-thin/${
            CATEGORY_ICON_SLUG[props?.categoryDetails?.slug]?.icon
          }`}
        >
          {titleText}
        </PageHead>
      )}

      {((!props.isForCategoryWithLocation && !props.isForLocation) ||
        (props.isForLocation && props.categoryDetails?.categoryDescription)) &&
        Array.isArray(tabs) &&
        tabs.length > 1 && (
          <div className="tab-buttons">
            <TabsWrapper contentBorder={false} role="tablist">
              {tabs.map((tab, index) => (
                <Tab
                  isForLocation={props.isForLocation}
                  key={index}
                  className={tab.id === activeTab ? 'active' : ''}
                  onClick={() => handleTabClick(tab.id)}
                  id={tab.id}
                  tabFontSize={'sm'}
                  role="presentation"
                  isIconPresent={tab.icon}
                >
                  <TabLink
                    role="tab"
                    aria-selected={tab.id === activeTab ? true : false}
                    className="show-content"
                  >
                    {tab.icon && <Icon className={tab.icon}></Icon>}
                    {tab.title.replace('{0}', categoryTitle)}
                  </TabLink>
                </Tab>
              ))}
            </TabsWrapper>
          </div>
        )}
      {!props.isForCategoryWithLocation &&
        activeTab == 'overview' &&
        categoryDescription && (
          <OverviewWrap id="overview-wrapper" role="tabpanel">
            <DescWrap
              expanded={isExpanded}
              dangerouslySetInnerHTML={{
                __html: visibleOverViewText ?? '',
              }}
            />
            {isForFJ && !isExpanded && (
              <ReadToggle
                onClick={() => setIsExpanded(!isExpanded)}
                noTopMargin
              >
                {isExpanded ? 'Less' : 'More'}
                <ArrowIcon expanded={isExpanded}>
                  <i className="fa fa-angle-right"></i>
                </ArrowIcon>
              </ReadToggle>
            )}
          </OverviewWrap>
        )}
      {activeTab == 'successstories' && (
        <>
          <SuccessStorys
            role="tabpanel"
            isForLocation={props.isForLocation}
            pageLocation={props.pageLocation}
            CompanySuccessStories={props.categorySuccessStories}
          />
        </>
      )}
      {activeTab == 'faq' && (
        <>
          <FAQWrap
            role="tabpanel"
            id="faq-wrapper"
            dangerouslySetInnerHTML={{
              __html: props.faqData?.faq || '',
            }}
          />
        </>
      )}
    </>
  );
};

export default CategoryDetails;
