import JobGuidePopover from '@components/remoteJob/jobGuidePopover';
import { useState } from 'react';
import { InfoIcon, InfoIconText, InfoIconWrapper } from './styled';
interface Props {
  onClick: any;
  toggleStatus: boolean;
  id: string;
  className?: string;
  mobTransform?: string;
}
const InfoPopOver = (props: Props) => {
  const { onClick, toggleStatus, id, className, mobTransform } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <InfoIconWrapper id={id} className={className ? className : ''}>
      <InfoIcon
        role="button"
        aria-label="Show job guide"
        className="fa fa-info-circle show-content"
        name="Job Guide"
        onClick={onClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {isFocused && (
          <InfoIconText role="tooltip" className="info-tooltip">
            Job Guide
          </InfoIconText>
        )}
      </InfoIcon>
      <JobGuidePopover
        toggleStatus={toggleStatus}
        mobTransform={mobTransform ? mobTransform : '-50%, 0%'}
      />
    </InfoIconWrapper>
  );
};

export default InfoPopOver;
