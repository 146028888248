import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const InfoIconWrapper = styled.div`
  position: relative;
`;
export const InfoIcon = styled.button`
  position: relative;
  padding: 0;
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      font-size: ${toolTipStyle.FontSizes.infoIcon};
      color: ${toolTipStyle.Colors.infoIcon};
      margin-left: 0.7rem;
      cursor: pointer;
    `}
`;

export const InfoIconText = styled.span<{ isFocused?: boolean }>`
  ${Mixin.Position({
    position: 'absolute',
    value: '-2.4rem auto auto -2.2rem',
  })}
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      font-size: ${toolTipStyle.FontSizes.infoIconText};
      color: ${toolTipStyle.Colors.infoIconText};
      background-color: ${toolTipStyle.BgColors.infoIconText};
    `}
  width: 7rem;
  text-align: center;
  border-radius: 0.6rem;
  padding: 0.5rem 0;
  line-height: 1.2rem;
`;
