import Mixin from '@styles/mixin';
import styled from 'styled-components';

export const HeadWrap = styled.div`
  ${Mixin.VerticalCenter()}
  margin-bottom: 2.4rem;
`;
export const SearchImg = styled.div`
  margin: 0;
`;
export const HeadMessage = styled.h2`
  padding: 0 1.2rem;
  ${Mixin.VerticalCenter()}
`;
export const RefineHeadLabel = styled.h4`
  font-weight: 600;
`;
export const SearchInputWrap = styled.div`
  ${Mixin.GridLayout({
    columnGap: '0',
    gridDimension: '1fr auto',
    mobDimension: '1fr auto',
  })}

  .refine-search-inpt {
    margin: 0;
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .refine-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
export const SearchWrap = styled.div`
  margin-bottom: 2.4rem;
  ${Mixin.GridLayout({
    gridDimension: 'auto 1fr',
    columnGap: '2.4rem',
    alignItems: 'center',
  })}
`;
export const SearchResultTips = styled.div`
  padding: 2rem;
`;
export const TroubleCheckList = styled.ul`
  padding-left: 3.2rem;
`;
export const TroubleCheckListItem = styled.li`
  list-style-type: disc;
  margin-bottom: 1rem;
`;
