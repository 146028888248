import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const FilterWrap = styled.div`
  ${Mixin.VerticalCenter({ wrap: 'wrap' })};
  gap: 0.4rem;
`;

export const ResetFilter = styled.a`
  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      font-size: ${FilterBtnStyle.FontSizes.reset};
      font-weight: ${FilterBtnStyle.FontWeights.iconFWNormal};
    `}
  text-decoration: none;
  margin-left: 0.5rem;
`;

export const MoreFilterLink = styled.a<{ isRCPortal?: boolean }>`
  ${({ theme: { FilterBtnStyle }, isRCPortal }) =>
    FilterBtnStyle &&
    css`
      font-size: ${isRCPortal
        ? FilterBtnStyle?.FontSizes?.moreFoterLinkText
        : null};
      font-weight: ${FilterBtnStyle.FontWeights.iconFWSemibold};
    `}
  margin-left: 0.8rem;
`;
