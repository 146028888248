import { checkIsFJFeatureAllowed } from '@components/common';
import JobNotes from '@components/jobNotes';
import SaveJobComponent from '@components/saveJobComponent';
import { IJobCardDeviceProps } from '@components/shared.types';
import { DateDifference, jobPostedDateFormatted } from '@utils/dateFormatter';
import { isRCPortal } from 'helper';
import {
  DeleteIcon,
  ExpiredText,
  Featured,
  HeadWrap,
  IconWraps,
  JobAge,
  JobCompany,
  LocationSpan,
  MiddleWrap,
  MutedTitle,
  NewTag,
  TagList,
  TagListWrap,
  TagsWrap,
  Title,
  TitleWrap,
  Wrapper,
} from '../styled';

const JobCard = (props: IJobCardDeviceProps) => {
  const { savedJob, openHostedJob } = props;

  const {
    featured,
    id,
    company,
    title,
    postedDate,
    jobTypes,
    allowedCandidateLocation,
    states,
    countries,
    cities,
    regionID,
    slug,
    expireOn,
    isFreeJob = false,
  } = props.jobsData;

  const savedJobsCommons = {
    saved: props.savedJob,
    savedJobsArray: props.savedJobsArray,
    setSavedJobsArray: props.setSavedJobsArray,
  };

  const isFJFeature = checkIsFJFeatureAllowed();

  return (
    <>
      <Wrapper
        noSpace={props.isPastJobs}
        isNoImage={true}
        isActive={props.isActive}
        featured={featured}
        onClick={props.handleCardClick}
        onAuxClick={(e) => (openHostedJob ? openHostedJob(e, id) : undefined)}
        id={id}
      >
        <div>
          <>
            {company && (
              <img
                src={
                  company.logo ||
                  `${process.env.NEXT_PUBLIC_CONTENT_URL}/images/streamline-icon-real-estate-building-person.svg`
                }
                width={65}
                height={65}
                alt={company && company.name}
                id={'img-' + id}
                title={company && company.name}
                aria-label="company logo"
              />
            )}
          </>
          <>
            {featured && (
              <Featured id={'featured-' + id} isRCPortal={isRCPortal}>
                {isRCPortal ? (
                  <span className="star-icons">&#9733;</span>
                ) : (
                  <i className="fa fa-star"></i>
                )}{' '}
                Featured
              </Featured>
            )}
            <HeadWrap>
              <>
                <TitleWrap>
                  {props.isCompanyPage ? (
                    <>
                      {!props.isPastJobs ? (
                        <Title
                          id={'title-' + id}
                          href={`/job-details/${slug}/`}
                          blurTest={false}
                          rel={!isFreeJob ? 'nofollow' : undefined}
                        >
                          {props.hideJobTitle
                            ? 'Company Name Here Company Name Here Company Name Here'.substring(
                                0,
                                title.length
                              )
                            : title}
                        </Title>
                      ) : (
                        <MutedTitle
                          isPastJob={props.isPastJobs}
                          id={'subtitle-' + id}
                        >
                          {props.hideJobTitle
                            ? 'Company Name Here Company Name Here Company Name Here'.substring(
                                0,
                                title.length
                              )
                            : title}
                        </MutedTitle>
                      )}
                      {company && (
                        <JobCompany id={'company-name-' + id}>
                          {company.name}
                        </JobCompany>
                      )}
                    </>
                  ) : (
                    <>
                      <Title
                        href={`/job-details/${slug}/`}
                        blurTest={false}
                        id={'job-name-' + id}
                        rel={!isFreeJob ? 'nofollow' : undefined}
                      >
                        {title}
                      </Title>
                      {DateDifference(String(postedDate)).daysDifference <
                        7 && (
                        <NewTag id={'new-' + id} isRCPortal={isRCPortal}>
                          New!
                        </NewTag>
                      )}

                      {expireOn &&
                        new Date(expireOn) < new Date() &&
                        props.localizationJobs && (
                          <ExpiredText id={'expired-' + id}>
                            {props.localizationJobs.expired_date_message}{' '}
                            {jobPostedDateFormatted(String(expireOn))}
                          </ExpiredText>
                        )}
                      {company && (
                        <>
                          <JobCompany id={'company-name-' + id}>
                            {company.name}
                          </JobCompany>
                        </>
                      )}
                    </>
                  )}
                </TitleWrap>
              </>

              <JobAge id={'job-age-' + id}>
                <SaveJobComponent
                  showIcon={true}
                  saved={savedJob}
                  jobId={id}
                  savedJobsArray={props.savedJobsArray}
                  setSavedJobsArray={props.setSavedJobsArray}
                  jobDetails={props.jobsData}
                  custumAttribute={props.customAttribute}
                  jobNumber={props.jobNumber}
                  jobsData={props.jobsList}
                  guid={props.guid}
                />

                <span id={'date-diff-' + id}>
                  {DateDifference(String(postedDate)).differenceResp}
                </span>
              </JobAge>
            </HeadWrap>

            <MiddleWrap>
              <TagsWrap
                isPremiumUser={true}
                wrapTag={
                  (props.totalTagsCount > 3 && props.activeDetailId !== '') ||
                  props.totalTagsCount > 5
                }
              >
                {props.remoteOptionsFilter &&
                  props.remoteOptionsFilter.length > 0 && (
                    <TagList
                      isPremiumUser={true}
                      isTagName
                      className="tag-name"
                      key={'remoteoption' + id}
                      id={'remoteoption-' + 0 + '-' + id}
                      isBadgeSuccess={isRCPortal}
                    >
                      {props.remoteOptionsFilter[0]}
                    </TagList>
                  )}
                {props.jobSchedulesData &&
                  props.jobSchedulesData.length > 0 && (
                    <TagList
                      isPremiumUser={true}
                      isTagName
                      className="tag-name"
                      key={'jobschedule' + id}
                      id={'jobschedule-' + 0 + '-' + id}
                      isBadgeSuccess={isRCPortal}
                    >
                      {props.jobSchedulesData[0]}
                    </TagList>
                  )}

                {jobTypes && jobTypes.length > 0 && (
                  <TagList
                    isPremiumUser={true}
                    isTagName
                    className="tag-name"
                    key={'jobtypes' + id}
                    id={'jobTypes-' + 0 + '-' + id}
                    isBadgeSuccess={isRCPortal}
                  >
                    {jobTypes[0]}
                  </TagList>
                )}

                {/* {salaryRange && salaryRange.length > 0 && (
                   <TagList
                     isPremiumUser={true}
                     isTagName
                     className="tag-name"
                     key={'tag' + 0}
                     id={'salartRange-' + 0 + '-' + id}
                   >
                     {formatSalaryRange(salaryRange)}
                   </TagList>
                 )} */}

                {/* <>
                  {allowedCandidateLocation && (
                    <>
                      <TagList
                        isPremiumUser={true}
                        isLocationFlag
                        className="allowed-location-flag"
                        key={'tag'}
                        id={'allowedlocation-flag-' + id}
                        isBadgeSuccess={isRCPortal}
                      >
                        {allowedCandidateLocation.map(
                          (tag: any, index: number) => (
                            <LocationSpan
                              className="allowed-location"
                              key={'tag' + index}
                              id={'allowedlocation-' + index + '-' + id}
                            >
                              {tag}
                              {allowedCandidateLocation.length - 1 != index
                                ? ','
                                : ''}
                            </LocationSpan>
                          )
                        )}
                        <IconWraps>
                          {showCountryFlag(
                            countries,
                            regionID,
                            cities,
                            states
                          ).map((itr: any) =>
                            itr.icon === 'icn-map-marker.png' ? (
                              <>
                                <LocationIconSpan id={'location-info-' + id}>
                                  <i
                                    className="fa fa-location-dot"
                                    title="Candidates need to be able to work in the City or State specified"
                                  ></i>
                                </LocationIconSpan>
                              </>
                            ) : (
                              <ListItemFlag key={itr.icon}>
                                <LazyImage
                                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/flagIcons/${itr.icon}`}
                                  imgAlt={itr.icon}
                                  imgTitle={itr.title}
                                  imgWidth={20}
                                  imgHeight={16}
                                />
                              </ListItemFlag>
                            )
                          )}
                        </IconWraps>
                      </TagList>
                    </>
                  )}
                </> */}

                {props.isSuggestedJobsPage && (
                  <DeleteIcon
                    id={'delete-btn-' + id}
                    onClick={(
                      e: React.MouseEvent<HTMLSpanElement, MouseEvent>
                    ) => {
                      props.deleteJob(e);
                    }}
                    className="fa fa-trash-can"
                  />
                )}
              </TagsWrap>
            </MiddleWrap>
            {allowedCandidateLocation && (
              <TagListWrap>
                <TagList
                  isPremiumUser={false}
                  isLocationFlag
                  className="allowed-location-flag"
                  key={'tag'}
                  id={'allowedlocation-flag-' + id}
                  isBadgeSuccess={isRCPortal}
                >
                  <IconWraps>
                    <span id={'location-info-' + id}>
                      <i
                        className="fa fa-location-dot"
                        title="Candidates need to be able to work in the City or State specified"
                      ></i>
                    </span>
                  </IconWraps>
                  {allowedCandidateLocation.map((tag: any, index: number) => (
                    <LocationSpan
                      className="allowed-location"
                      key={'tag' + index}
                      id={'allowedlocation-' + index + '-' + id}
                    >
                      {tag}
                      {allowedCandidateLocation.length - 1 != index ? ',' : ''}
                    </LocationSpan>
                  ))}
                </TagList>
              </TagListWrap>
            )}

            {isFJFeature && (
              <div id={'jobnote-' + id}>
                <JobNotes
                  jobId={id}
                  applyJobStatus={props.jobDetails?.applyJobStatus}
                  appliedDate={props.jobDetails?.appliedDate}
                  savedJobsCommons={savedJobsCommons}
                />
              </div>
            )}
          </>
        </div>
      </Wrapper>
    </>
  );
};

export default JobCard;
