import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';
const blob_path = `${process.env.NEXT_PUBLIC_CONTENT_URL}/images`;
export const BtnWrap = styled.div`
  padding-top: 1.6rem;
  padding-bottom: 2.9rem;
`;
export const JobCardWrapper = styled.div`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      border-top: 0;
    `}
`;

export const SubJobCardWrapper = styled.div``;

export const PopUpwrapper = styled.div`
  position: relative;
  line-height: 1.2;
  font-weight: 500;
  margin: 0 0 0.8rem;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      color: ${JobCardStyle.Colors.PopUpwrapper};
      font-size: ${JobCardStyle.FontSizes.PopUpwrapperLarge};
      ${device.mobileLg} {
        font-size: ${JobCardStyle.FontSizes.PopUpwrapper};
      }
    `}
  .featuredjob-popover {
    display: inline;
    margin-left: 0.5rem;
  }
`;
export const BreadcrumbWrapper = styled.div<{ isCategoryPage?: boolean }>`
  padding-top: 1.6rem;
`;
export const BreadcrumbOuterWrapper = styled.div`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      background-color: ${JobCardStyle.BgColors.BreadcrumbOuterWrapper};
    `}
`;
export const NoResultWrap = styled.div`
  margin: 2.4rem 0 0;
  ul {
    margin: 0;
    padding-left: 3.2rem;
  }
`;

export const Wrapper = styled.div<{ isSearchPage?: boolean,isLegitimate?:boolean }>`
  ${device.mobileTab} {
    padding: ${({ isSearchPage,isLegitimate }) => isSearchPage && `${isLegitimate ? '0' : '3rem 0 0'}`};
  }
`;
export const PromoRocketCta = styled.div`
  ${Mixin.HorizontalCenter({ justify: 'space-between', wrap: 'wrap' })}
  border-radius: 0.8rem;
  margin-bottom: 1.4rem;
  overflow: hidden;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      background: ${JobCardStyle.BgColors.promoRocketCta};
      border: 0.1rem solid ${JobCardStyle.BdrColors.promoRocketCta};
    `}
`;
export const RocketImageWrap = styled.div`
  width: 25%;
  background: url(${blob_path}/rocket.svg) no-repeat right center;
  background-size: cover;
`;
export const RocketImage = styled.img`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const PromoContent = styled.div`
  width: 75%;
  padding: 1.48rem 0.6rem 0.37rem 0;
  text-align: center;
  .promo-join-btn {
    ${({ theme: { JobCardStyle } }) =>
      JobCardStyle &&
      css`
        font-size: ${JobCardStyle.FontSizes.promoContent};
      `}
    margin-bottom: 0.74rem;
    padding: 0.35rem 1rem;
    border-radius: 0.2rem;
    font-weight: 700;
  }
`;
export const PromoHead = styled.strong`
  display: block;
  margin: 0 0 0.37rem 0;
  line-height: 1.2;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      color: ${JobCardStyle.Colors.promoHead};
      font-size: ${JobCardStyle.FontSizes.promoHead};
    `}
`;
export const PromoHeadSub = styled.p`
  margin-bottom: 0.74rem;
  line-height: 1.46;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      color: ${JobCardStyle.Colors.promoHeadSub};
      font-size: ${JobCardStyle.FontSizes.promoHeadSub};
    `}
`;

export const BottomDrawerWrap = styled.div`
  #full-size-popup-container {
    z-index: 9999;
  }
`;

export const JobCountWrapper = styled.div`
  display: none;
  font-size: 1.8rem;
  padding: 0 0.5rem 1.5rem;
  font-weight: 500;
`;
export const SearchFilterWrapper = styled.div<{
  isRCPortal?: boolean;
  isRemoteJobsPage?: boolean;
}>`
  ${({ theme: { JobCardStyle }, isRCPortal, isRemoteJobsPage }) =>
    JobCardStyle &&
    isRCPortal &&
    css`
      background-color: ${JobCardStyle?.BgColors?.searchFilterWrapper};
      padding: 2.4rem 0;
      margin-bottom: ${isRemoteJobsPage ? '4rem' : '8rem'};
    `}
`;

export const SearchTipFilterWrappers = styled.div<{
  isRCPortal?: boolean;
  isRemoteJobsPage?: boolean;
}>`
  ${({ theme: { JobCardStyle }, isRCPortal, isRemoteJobsPage }) =>
    JobCardStyle &&
    isRCPortal &&
    css`
      background-color: red;
      padding: 2.4rem 0;
      margin-bottom: ${isRemoteJobsPage ? '4rem' : '8rem'};
    `}
`;

export const CompaniesHeading = styled.h1`
  margin-bottom: 0.8rem;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      font-size: ${JobCardStyle?.FontSizes?.companiesHeading};
      font-family: ${JobCardStyle?.FontFamily?.companiesHeading};
      font-weight: 700;
    `}
`;
export const CompaniesSubHeading = styled.p`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      font-size: ${JobCardStyle?.FontSizes?.companiesSubHeading};
      font-family: ${JobCardStyle?.FontFamily?.companiesSubHeading};
      font-weight: 400;
    `}
`;
export const ViewMoreBtn = styled.a<{
  isTextMore?: boolean;
  isTextless?: boolean;
}>`
  margin-bottom: 1.8rem;
  display: inline-block;
  position: relative;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      font-size: ${JobCardStyle?.FontSizes?.ViewMoreBtn};
    `}
  &::before {
    content: '';
    display: inline-block;
    border-width: 0.25rem 0.25rem 0px 0px;
    border-color: rgb(0, 110, 165);
    border-style: solid;
    height: 0.7rem;
    width: 0.7rem;
    transform: ${({ isTextMore }) =>
      isTextMore ? 'rotate(135deg)' : 'rotate(-45deg)'};
    position: absolute;
    right: -20px;
    bottom: ${({ isTextMore }) => (isTextMore ? '0.6rem' : '0.3rem')};
  }
`;