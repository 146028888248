import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Regex } from '../constant';
import { PaginatedWrap } from './styled';

const ReactPaginate = dynamic(() => import('react-paginate'), { ssr: false });

type Props = {
  pageCount: number;
  paginationRef?: React.RefObject<HTMLDivElement>;
  hideDetails?: () => void;
  isMobileView?: boolean;
};

const PaginationComponent = (props: Props) => {
  const { pageCount, isMobileView } = props;
  const router = useRouter();

  const match = router.asPath.match(Regex.getPage);
  const page = match ? parseInt(match[1]) : 1;

  const handlePageClick = async (data: any) => {
    props.hideDetails && props.hideDetails();
    const selectedPage = data.selected + 1;

    const url = new URL(document.location.href);
    const params = url.searchParams;
    // Preserve existing hash
    const existingHash = url.hash;
    if (selectedPage === 1) {
      params.delete('page');
    } else {
      params.set('page', selectedPage);
    }
    const queryString = params.toString();
    const newUrl = `${url.origin}${url.pathname}${
      queryString ? `?${queryString}` : ''
    }${existingHash}`;
    document.location.href = newUrl;
  };

  return pageCount < 2 ? (
    <></>
  ) : (
    <PaginatedWrap>
      <div ref={props.paginationRef}>
        <ReactPaginate
          previousLabel={'prev'}
          nextLabel={'next'}
          pageCount={pageCount}
          marginPagesDisplayed={isMobileView ? 2 : 3}
          pageRangeDisplayed={isMobileView ? 3 : 7}
          onPageChange={handlePageClick}
          breakLabel={'...'}
          breakClassName={'break-me'}
          containerClassName={'pagination'}
          activeClassName={'active'}
          forcePage={page - 1}
        />
      </div>
    </PaginatedWrap>
  );
};

export default PaginationComponent;
