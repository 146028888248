import { formatSalaryRange } from '@components/common';
import { EducationLevelText } from '@components/common/constant';
import BannerUnlock from '@components/companyDetails/bannerUnlock';
import JobInfoFooter from '@components/jobInfoFooter';
import { CompanyDetails, JobDetailsResults } from '@components/shared.types';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { isRCPortal } from 'helper';
import {
  ContentWrap,
  DatailWrap,
  Detail,
  JobDescription,
  JobLocationsWrap,
  JobSummary,
  ListItem,
  ListWrap,
  Subheading,
  Title,
  Wrapper,
} from './styled';

type Props = {
  jobDetails?: JobDetailsResults;
  jobId: string;
  companyDetails: CompanyDetails;
  isPremiumUser?: boolean;
  isHybrid?: boolean;
  savedJobsCommons: any;
  showJobDetailButton?: boolean;
  localization: any;
  isHostedPage?: boolean;
  referer?: string;
  applyPopupRef?: React.RefObject<HTMLDivElement>;
  jobNumber?: number;
  jobsData?: any;
  queryParams?: any;
  guid?: string;
  role?: string;
  deviceType?: string;
  blurTitle?: boolean;
  hideNote?: boolean;
  hideCopyReport?: boolean;
  unlockBannerSrc?: string;
  isPadding?: boolean;
};

const JobInfo = (props: Props) => {
  const isMobileView =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';

  if (!props.jobDetails) {
    return null;
  }

  const {
    id,
    slug,
    salaryRange,
    company,
    jobLocations,
    categories,
    jobTypes,
    jobSchedules,
    careerLevel,
    jobSummary,
    description,
    remoteOptions,
    educationLevels,
    applyURL,
    applyJobStatus,
    appliedDate,
    accolades,
    jobBenefits,
    reportJobStatus,
    travelRequired,
    title,
  } = props.jobDetails;

  return (
    <ContentWrap role={props.role} isPadding={props.isPadding}>
      {!(props.isPremiumUser || props.isHybrid) && props.isHostedPage && (
        <JobSummary>{jobSummary || ''}</JobSummary>
      )}
      <ListWrap
        isPremium={props.isPremiumUser}
        isHybrid={props.isHybrid}
        id="detail-list-wrapper"
        isRCPortal={isRCPortal}
      >
        {remoteOptions && (
          <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
            <Title isRCPortal={isRCPortal}>
              {props.localization.work_level}
            </Title>
            {remoteOptions?.map((remote: any) => (
              <Detail key={remote} isRCPortal={isRCPortal}>
                {remote?.replace(' Work', '')}
              </Detail>
            ))}
          </ListItem>
        )}

        {!isMobileView &&
          jobLocations &&
          jobLocations.filter(Boolean).length > 0 && (
            <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
              <Title isRCPortal={isRCPortal}>
                {props.localization.location}
              </Title>
              <JobLocationsWrap
                isPremium={props.isPremiumUser}
                isHybrid={props.isHybrid}
                isRCPortal={isRCPortal}
              >
                <span>
                  {!jobLocations
                    .join(', ')
                    ?.toLowerCase()
                    ?.includes('us national') &&
                    !jobLocations
                      .join(', ')
                      ?.toLowerCase()
                      ?.includes('work from anywhere') &&
                    props.localization.location_prefix}{' '}
                  {jobLocations.join(', ')}
                </span>
              </JobLocationsWrap>
            </ListItem>
          )}

        {salaryRange ? (
          <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
            <Title isRCPortal={isRCPortal}>{props.localization.salary}</Title>
            <Detail isRCPortal={isRCPortal}>
              {formatSalaryRange(salaryRange)}
            </Detail>
          </ListItem>
        ) : (
          <></>
        )}
        {jobBenefits &&
          jobBenefits?.length > 0 &&
          !(props.isPremiumUser || props.isHybrid) && (
            <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
              <Title isRCPortal={isRCPortal}>
                {props.localization.benefits}
              </Title>
              <Detail isRCPortal={isRCPortal}>
                {jobBenefits.map((jobBenefits) => jobBenefits).join(', ')}
              </Detail>
            </ListItem>
          )}
        {!(props.isPremiumUser || props.isHybrid) && (
          <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
            <Title isRCPortal={isRCPortal}>{props.localization.company}</Title>
            <Detail
              className={
                props.isPremiumUser || props.isHybrid ? '' : 'add-blur'
              }
              isRCPortal={isRCPortal}
            >
              {props.localization.sample_company_details}
            </Detail>
          </ListItem>
        )}
        {!(props.isPremiumUser || props.isHybrid) && isMobileView && (
          <>
            <BannerUnlock
              imgSrc={
                process.env.NEXT_PUBLIC_CONTENT_URL +
                `/images/${props.unlockBannerSrc}`
              }
              className="mob_nonpremium_banner"
              unlock_banner_text={props.localization.unlock_banner}
              isHostedPage={props.isHostedPage}
              jobDetails={props.jobDetails}
              jobNumber={props.jobNumber}
              jobsData={props.jobsData}
              queryParams={props.queryParams}
              guid={props.guid}
              deviceType={props.deviceType}
              isPremiumUser={props.isPremiumUser}
              isHybrid={props.isHybrid}
            />
          </>
        )}

        {!(props.isPremiumUser || props.isHybrid) && !isMobileView && (
          <>
            <BannerUnlock
              imgSrc={
                process.env.NEXT_PUBLIC_CONTENT_URL +
                `/images/${props.unlockBannerSrc}`
              }
              unlock_banner_text={props.localization.unlock_banner}
              isHostedPage={props.isHostedPage}
              jobDetails={props.jobDetails}
              jobNumber={props.jobNumber}
              jobsData={props.jobsData}
              queryParams={props.queryParams}
              guid={props.guid}
            />
          </>
        )}

        {jobTypes && (
          <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
            <Title isRCPortal={isRCPortal}>{props.localization.job_type}</Title>
            <Detail isRCPortal={isRCPortal}>{jobTypes.join(', ')}</Detail>
          </ListItem>
        )}
        {jobSchedules && jobSchedules?.length > 0 && (
          <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
            <Title isRCPortal={isRCPortal}>
              {props.localization.job_schedule}
            </Title>
            <Detail isRCPortal={isRCPortal}>
              {jobSchedules.map((schedule) => schedule).join(', ')}
            </Detail>
          </ListItem>
        )}
        {careerLevel && careerLevel?.length > 0 && (
          <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
            <Title isRCPortal={isRCPortal}>
              {props.localization.career_level}
            </Title>
            {careerLevel && careerLevel?.length > 0 && (
              <Detail isRCPortal={isRCPortal}>
                {careerLevel.map((clevel) => clevel).join(', ')}
              </Detail>
            )}
          </ListItem>
        )}
        {travelRequired && travelRequired.length > 0 && (
          <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
            <Title isRCPortal={isRCPortal}>
              {props.localization.travel_required}
            </Title>
            <Detail isRCPortal={isRCPortal}>{travelRequired}</Detail>
          </ListItem>
        )}
        {educationLevels && educationLevels.length > 0 && (
          <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
            <Title isRCPortal={isRCPortal}>
              {props.localization.education}
            </Title>
            <DatailWrap>
              {educationLevels[0] === EducationLevelText.noEduSpecified ? (
                <Detail isRCPortal={isRCPortal}>
                  {EducationLevelText.sorryTxt}
                </Detail>
              ) : (
                educationLevels.map((educationLevel: string, index: number) => (
                  <Detail key={educationLevel} isRCPortal={isRCPortal}>
                    {index === educationLevels.length - 1
                      ? educationLevel
                      : `${educationLevel},`}
                  </Detail>
                ))
              )}
            </DatailWrap>
          </ListItem>
        )}
        {jobBenefits &&
          jobBenefits?.length > 0 &&
          (props.isPremiumUser || props.isHybrid) && (
            <ListItem isHostedPage={props.isHostedPage} isRCPortal={isRCPortal}>
              <Title isRCPortal={isRCPortal}>
                {props.localization.benefits}
              </Title>
              <Detail isRCPortal={isRCPortal}>
                {jobBenefits.map((jobBenefits) => jobBenefits).join(', ')}
              </Detail>
            </ListItem>
          )}
      </ListWrap>

      {(props.isPremiumUser || props.isHybrid) && (
        <>
          <Wrapper id="about-the-role-wrapper" isRCPortal={isRCPortal}>
            <Subheading isRCPortal={isRCPortal}>
              {props.localization.about_role}
            </Subheading>
            <>
              <JobDescription
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </>
          </Wrapper>
          {/* {props.companyDetails?.summary ? (
            <AboutComWrapper id="about-company-wrapper">
              <AboutCompTitle>
                {props.localization.about_company}
              </AboutCompTitle>
              <TitleImageWrap>
                {props.companyDetails?.logo ? (
                  <img
                    src={props.companyDetails?.logo}
                    alt="company-logo"
                    width={48}
                    height={48}
                  />
                ) : (
                  <ApprovedWrap>
                    <img
                      width={74}
                      height={33}
                      id={`company-img-${id}`}
                      src={getAbsoluteImgPath(`/images/${portalLogo}`)}
                      alt={company.name}
                    />
                    <ApprovedText>
                      {props.localization.approved_employer}
                    </ApprovedText>
                  </ApprovedWrap>
                )}
                <AboutCompName>{props.companyDetails?.name}</AboutCompName>
              </TitleImageWrap>
              <AccoladeTitle>{props.localization.overview}</AccoladeTitle>
              <OverviewDesc
                dangerouslySetInnerHTML={{
                  __html: props.companyDetails.summary,
                }}
              ></OverviewDesc>
              <LearnCTAWrap>
                <Button
                  text={`${props.localization.learn_more} ${props.companyDetails?.name}`}
                  buttonType="transparent"
                  fontWeight="bold"
                  url={`/remote-jobs/company/${props.companyDetails?.slug}`}
                />
              </LearnCTAWrap>
            </AboutComWrapper>
          ) : (
            <></>
          )} */}
          {(props.isPremiumUser || props.isHybrid) && (
            <JobInfoFooter
              jobId={props.jobId}
              applyURL={applyURL}
              applyJobStatus={applyJobStatus}
              appliedDate={appliedDate}
              savedJobsCommons={props.savedJobsCommons}
              reportJobStatus={reportJobStatus}
              showJobDetailButton={props.showJobDetailButton}
              referer={props.referer}
              isHostedPage={props.isHostedPage}
              applyPopupRef={props.applyPopupRef}
              jobDetails={props.jobDetails}
              jobNumber={props.jobNumber}
              jobsData={props.jobsData}
              queryParams={props.queryParams}
              guid={props.guid}
              hideNote={props.hideNote}
              hideCopyReport={props.hideCopyReport}
              slug={slug}
            />
          )}
        </>
      )}
      {props.isHybrid && !props.isPremiumUser && (
        <>
          <BannerUnlock
            imgSrc={
              process.env.NEXT_PUBLIC_CONTENT_URL +
              `/images/${props.unlockBannerSrc}`
            }
            className={isMobileView ? 'mob_nonpremium_banner' : ''}
            unlock_banner_text={props.localization.unlock_banner}
            isHostedPage={props.isHostedPage}
            jobDetails={props.jobDetails}
            jobNumber={props.jobNumber}
            jobsData={props.jobsData}
            queryParams={props.queryParams}
            guid={props.guid}
            deviceType={props.deviceType}
            isPremiumUser={props.isPremiumUser}
            isHybrid={props.isHybrid}
          />
        </>
      )}
    </ContentWrap>
  );
};

export default JobInfo;
