import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const FlyerWrapper = styled.div`
  position: fixed;
  z-index: 99;
  height: calc(100vh - 5.6rem);
  inset: auto 0 0 0;
  overflow-y: auto;
  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      background-color: ${FilterBtnStyle.BgColors.flyerwrapbg};
    `}
`;
export const FlyerHeader = styled.div`
  ${Mixin.GridLayout({
    gridDimension: '1fr 1fr 1fr',
    mobDimension: '1fr 1fr 1fr',
    columnGap: '1.1rem',
  })}
  margin: 1.5rem 0;
  padding: 2.2rem 1.5rem 0;
  .reset_btn {
    padding: 0;
    text-align: left;
  }
  .flyerCross {
    padding: 0;
    text-align: right;
  }
  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      .reset_btn {
        font-size: ${FilterBtnStyle.FontSizes.resetcrossbtn};
      }
      .flyerCross {
        font-size: ${FilterBtnStyle.FontSizes.resetcrossbtn};
        color: ${FilterBtnStyle.Colors.crossiconcolor};
      }
    `}
`;
export const FilterText = styled.span`
  text-align: center;
  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      font-size: ${FilterBtnStyle.FontSizes.filtertexthead};
    `}
`;

export const FlyerBody = styled.div`
  margin: 0;
  overflow-y: auto;
  padding-bottom: 8rem;
`;
export const FlyerFooter = styled.div`
  position: fixed;
  inset: auto 0 0 0;
  padding: 1.5rem 1.2rem;
  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
    .show-jobs-btn{
      background-color: ${FilterBtnStyle.BgColors.showJobs};
      border-color: ${FilterBtnStyle.BdrColors.showJobs};
    }
      background-color: ${FilterBtnStyle.BgColors.flyerfooterbg};
    `}
`;
export const FilterListWrap = styled.div`
  padding: 1.5rem;

  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      border-bottom: 0.1rem solid ${FilterBtnStyle.BdrColors.filterBdr};
    `}
`;

export const MobSortFilterWrap = styled.div`
  ${Mixin.VerticalCenter()};
  padding: 1.5rem;

  .sort-btn {
    padding: 0.6rem 0.8rem;
  }

  ${({ theme: { FilterBtnStyle } }) =>
    FilterBtnStyle &&
    css`
      border-bottom: 0.1rem solid ${FilterBtnStyle.BdrColors.filterBdr};
    `}
`;
