import { isPortalContent } from 'helper';

export const COMPANY_TABS = [
  {
    id: 'overview',
    title: 'Overview',
    icon: 'fa-regular fa-building fa-fw',
  },
  {
    id: 'successstories',
    title: 'Success Stories',
    icon: 'fa fa-commenting fa-fw',
  },
  {
    id: 'faq',
    title: 'FAQ',
    icon: null,
  },
];
export const LOCATION_TABS = [
  {
    id: 'overview',
    title: '{0} Overview',
    icon: null,
  },
  {
    id: 'successstories',
    title: 'Success Stories',
    icon: null,
  },
];
export const successtory = [
  {
    content_id: 340,
    success_name: 'Robert',
    success_initial: 'R',
    success_city: 'Scottville',
    success_state: 'MI',
    success_country: 'United States',
    success_comment: `Thanks again! ${isPortalContent} is a great tool for finding a work-at-home job. They weed out the fake job offers and have a lot of real remote jobs for great companies. I'm disabled, and a desk job at home is really my only option. This job turned my life around.`,
    success_job_title: 'Tier 1 Advisor',
    success_company_name: 'Kelly',
  },
  {
    content_id: 342,
    success_name: 'Michelle',
    success_initial: 'A',
    success_city: 'Lancaster',
    success_state: 'TX',
    success_country: 'United States',
    success_comment: `This site was a tremendous help to myself and my family. I was looking for an opportunity that would allow me to work from home without sacrificing time with my husband and two young daughters. In a very short amount of time, ${isPortalContent} changed my life. Thanks!! I used ${isPortalContent} for only about two weeks before I received an email for a job interview. I did the phone interview and was hired on the spot.`,
    success_job_title: 'Customer Service',
    success_company_name: 'Kelly',
  },
];
