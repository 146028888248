import { getAbsoluteImgPath } from '@components/common';
import ApplyButton from '@components/common/applyButton';
import JobInfo from '@components/jobInfo';
import SaveJobComponent from '@components/saveJobComponent';
import { IJobDetailsDeviceProps } from '@components/shared.types';
import { jobPostedDateFormatted } from '@utils/dateFormatter';
import { isRCPortal, portalLogo } from 'helper';
import {
  ApprovedText,
  ApprovedWrap,
  ButtonWrapper,
  CompImage,
  CompName,
  DetailWrapper,
  ImageLink,
  JobTime,
  JobTitle,
  StickyWrapper,
  TitleImageWrap,
  Wrapper,
} from '../styled';

const JobDetails = (props: IJobDetailsDeviceProps) => {
  const {
    hasBoxShadow,
    applyPopupRef,
    companyLogoUrl,
    savedJobsCommons,
    jobDetails,
    companyDetails,
    showJobDetailButton,
    isHostedPage,
    isSearchPage,
    deviceType,
    hideSaveJobButton,
    hideNote,
    hideCopyReport,
    isLegitimate,
    isCategoryPage,
    isCompanyComponent
  } = props;

  return (
    <Wrapper
      {...props}
      ref={props.targetElement}
      isSearchPage={isSearchPage}
      id="inset-wrapper"
      isRCPortal={isRCPortal}
      isLegitimate={isLegitimate}
      isCategoryPage={isCategoryPage}
      isCompanyComponent={isCompanyComponent}
    >
      <StickyWrapper
        isPremiumUser={true}
        isHostedPage={isHostedPage}
        hasShadow={hasBoxShadow}
      >
        <DetailWrapper>
          <TitleImageWrap isPremiumUser={true}>
            <ImageLink
              as={props.isPremiumUser && props.isLoggedIn ? 'a' : 'div'}
              id={`company-link-${jobDetails.id}`}
              href={
                props.isPremiumUser && props.isLoggedIn
                  ? `/remote-jobs/company/${jobDetails.company.slug}`
                  : undefined
              }
              target={
                props.isPremiumUser && props.isLoggedIn ? '_blank' : undefined
              }
              rel={
                props.isPremiumUser && props.isLoggedIn ? 'external' : undefined
              }
              isRCPortal={isRCPortal}
            >
              {companyLogoUrl ? (
                <CompImage
                  id={`company-img-${jobDetails.id}`}
                  src={companyLogoUrl}
                  alt={jobDetails.company.name}
                />
              ) : (
                <ApprovedWrap>
                  <img
                    width={74}
                    height={33}
                    id={`company-img-${jobDetails.id}`}
                    src={getAbsoluteImgPath(`/images/${portalLogo}`)}
                    alt={jobDetails?.company?.name}
                  />
                  {!isRCPortal && (
                    <ApprovedText>
                      {props.localization.approved_employer}
                    </ApprovedText>
                  )}
                </ApprovedWrap>
              )}
            </ImageLink>
            <div>
              <JobTitle blurTest={false} isRCPortal={isRCPortal}>
                {props.jobDetails.title}
              </JobTitle>
              {props.jobDetails?.company && (
                <CompName isRCPortal={isRCPortal}>
                  {jobDetails.company.name}
                </CompName>
              )}
            </div>
          </TitleImageWrap>

          <JobTime>
            {jobPostedDateFormatted(String(props.jobDetails.postedDate))}
          </JobTime>
        </DetailWrapper>
        <ButtonWrapper>
          <ApplyButton
            jobId={props.jobId}
            isJobAlreadyApplied={false}
            applyURL={jobDetails.applyURL}
            applyJobStatus={jobDetails.applyJobStatus}
            applyPopupRef={applyPopupRef}
            isHostedPage={props.isHostedPage}
            jobDetails={props.jobDetails}
            jobNumber={props.jobNumber}
            jobsData={props.jobsData}
            queryParams={props.queryParams}
            guid={props.guid}
            buttonSize={isHostedPage ? 'lg' : 'md'}
            localization={props.localization}
            isLoggedIn={props.isLoggedIn}
          />
          {!hideSaveJobButton && (
            <SaveJobComponent
              jobId={props.jobId}
              {...savedJobsCommons}
              isHostedPage={isHostedPage}
              mediumButton
              jobDetails={props.jobDetails}
              jobNumber={props.jobNumber}
              jobsData={props.jobsData}
              guid={props.guid}
            />
          )}
        </ButtonWrapper>
      </StickyWrapper>

      <JobInfo
        jobDetails={jobDetails}
        jobId={props.jobId}
        companyDetails={companyDetails}
        isPremiumUser={true}
        savedJobsCommons={savedJobsCommons}
        showJobDetailButton={showJobDetailButton}
        localization={props.localization}
        referer={props.referer}
        isHostedPage={props.isHostedPage}
        applyPopupRef={applyPopupRef}
        jobNumber={props.jobNumber}
        jobsData={props.jobsData}
        queryParams={props.queryParams}
        guid={props.guid}
        role="tabpanel"
        deviceType={deviceType}
        blurTitle={false}
        hideNote={hideNote}
        hideCopyReport={hideCopyReport}
      />
    </Wrapper>
  );
};

export default JobDetails;
