import {
  acceptOnlyFilterdKeys,
  checkIsFJFeatureAllowed,
  decodeCompleteEncodedString,
  extractActualSavedSearchValue,
  getClientSettingValue,
  getNumFormatter,
  getTotalSubcategoriesAndCategory,
  getURLSearchParams,
  isOnlySavedSearchQueryParam,
  jobCountFilters,
  mapIdTONameOfCategories,
  modifyLocationLookupData,
  modifySelectedLocation,
  setClientSettings,
  updateSearchActivity,
} from '@components/common';
import {
  SAVED_SEARCH_BUTTON_STATE,
  US_GLOBAL_LOCATION,
  _CONSTANTS,
  countrySubTitlesArr,
  readValueUsingKey,
  selectedFiltersOrder,
} from '@components/common/constant';
import {
  jobSearchPerformedTrackEvent,
  jobSearchResultsInteractions,
} from '@components/common/event-tracker';
import {
  mapQueryParams,
  queryStringManipulation,
  removeItemsFromFilterObj,
} from '@components/common/filterHelper';
import InfoPopOver from '@components/common/infoPopover';
import SearchFilter from '@components/common/searchFilter';
import Filter from '@components/common/searchFilter/filter';
import ToolTipSection from '@components/common/toolTip';
import { jobSearchFilters } from '@components/common/typeCd';
import FilterFlyer from '@components/filterFlyer';
import { StyledContainerMd } from '@components/layout/styled';
import {
  CategoryProps,
  CountryResponseProps,
  IDeviceType,
  ILoggedInUser,
  LocationLookupItem,
} from '@components/shared.types';
import { Button, checkCookieHeaders } from '@license-admin/boldfjcomponents';
import { track } from '@utils/eventTrack';
import useDebounce from '@utils/useDebounce';
import {
  getJobTitle,
  getLocationLookup,
  getSavedSearchById,
  pageView,
  saveJobSearchApi,
  updateSaveJobSearchApi,
} from 'API/ApiHelpers';
import { getGuidValue, getSearchType } from 'helper/eventTrackingUtils';
import { Action, ClickOption } from 'helper/eventTrackingUtils/constant';
import { useOutsideClick } from 'helper/hooks/useOutsideClick';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { SaveSearchModal } from '../saveSearchModal/saveSearchModal';
import {
  FilterButtonWrap,
  FilterWrapper,
  HeadingWrapper,
  IconWrap,
  InnerWrap,
  JobTypeWrapper,
  ListItem,
  ListWrap,
  MbFilterWrap,
  MbWorkAnywhereWrap,
  RemoteWorkTip,
  ResultHeading,
  ResultWrapper,
  SaveBtnWrap,
  SaveLinks,
  SavePopup,
  SeacrhFilterFooter,
  SearchResultHeading,
  SearchResultsWrapper,
  TipsDesc,
  TipsHeader,
  TipsText,
  TipsTitle,
  WorkAnyCheckWrap,
  WorkAnywhereWrap,
  Wrapper,
} from './styled';

type IProps = {
  jobFilters: Array<JobFilters>;
  selectedCountry?: string;
  paginationData: any;
  queryParams: { [key: string]: Array<string> | string | undefined };
  location?: string;
  searchedJobTitle?: string;
  selectedFilters?: { [key: string]: Array<string> };
  localization: any;
  showJobsCount?: boolean;
  pageLocation?: string;
  isCategoryJobsPage?: boolean;
  isPremiumUser?: boolean;
  showWorkAnywhereInUsCheckbox?: boolean;
  showWorkAnywhereInWorldCheckbox?: boolean;
  isCategorySubCategory?: boolean;
  deviceType?: IDeviceType;
  countyList?: CountryResponseProps[];
  categoryDetails: CategoryProps;
  openSearchOnLocationChange?: boolean;
  showAllDropFilters?: boolean;
  jobsData: any;
  setGuid?: Function | undefined;
  guid?: string;
  isForLocation?: boolean;
  isWorkAnywhereInWorldChecked?: boolean;
  isWorkAnywhereInUsChecked?: boolean;
  saveSeachButtonState?: string;
  mainWrapperRef?: any;
  hideDetails?: any;
  dateButtonRef?: any;
  relevanceButtonRef?: any;
  hideJobCount?: boolean;
  isSearchPage?: boolean;
  searchPageExpVariant?: number;
  isRemoteJobsPage?: boolean;
  isRCPortal?: boolean;
  isLoggedIn?: boolean;
  isCategoryPage?: boolean;
  noOfFiltersSelected?: any;
  isOpenDetailJobId?: any;
  isLegitimate?: boolean;
};

type JobFilters = {
  name: string;
  categoryWithCount: CategoryWithCount;
  slug: string;
};

type CategoryWithCount = {
  [key: string]: number;
};

let enableFilterSearch = {
  keyword: false,
  location: false,
};

type SingleFilterArrayType = {
  [key: string]: Array<string>;
};

type SelectedFiltersType = SingleFilterArrayType;

const SearchTipsFilter = (props: IProps) => {
  const {
    location,
    searchedJobTitle = '',
    deviceType,
    countyList,
    jobsData,
    setGuid,
    mainWrapperRef,
    isPremiumUser,
    isRemoteJobsPage,
    isRCPortal,
    isOpenDetailJobId,
    isSearchPage,
    isCategoryJobsPage,
    isLoggedIn,
    isCategoryPage,
    isLegitimate,
  } = props;

  const router = useRouter();
  const claims: any = getClientSettingValue(_CONSTANTS.CLAIMS);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>(
    props.selectedFilters || {}
  );
  const [filtersBeforeLoad, selectedFiltersBeforeLoad] =
    useState<SelectedFiltersType>(props.selectedFilters || {});

  const [isFunctionCalled, setIsFunctionCalled] = useState<boolean>(false);
  const [filterQs, setFilterQs] = useState<string>('');
  let getRemoteOptions = mapQueryParams(router.query).remoteoptions;

  const [savePopup, setSavePopup] = useState(false);

  // If Location is not selected and Remote Options is 100% Remote Work
  let isRemoteOptionWithoutLoc =
    getURLSearchParams(router.query, 'joblocations') === '' &&
    getRemoteOptions?.includes('100% Remote Work');

  const [toggleStates, setToggleStates] = useState({
    popover: false,
    SavedSearchPopup: false,
    savedSearchButtonState:
      props.saveSeachButtonState ?? SAVED_SEARCH_BUTTON_STATE.save_search,
  });

  const [inputParam, setInputParams] = useState<any>({
    searchkeyword: '',
    selectedLocation: '',
    selectedKeyword: searchedJobTitle || '',
    preSelectedCountry: props.selectedCountry || '',
    encodedLocation: '',
    location: location || '',
    country: '',
    latLong: '',
    isWorkAnywhereInUsChecked: props.isWorkAnywhereInUsChecked ?? false,
    isWorkAnywhereInWorldChecked: props.isWorkAnywhereInWorldChecked ?? false,
  });

  const numFormat = getNumFormatter();
  const keywordDebounce = useDebounce(inputParam.searchkeyword, 500);
  const locationDebounce = useDebounce(inputParam.location, 500);
  const [activeJob, setActiveJob] = useState<string>(
    router.query && router.query.sortbyposteddate ? 'date' : 'relevance'
  );
  const [searchResults, setSearchResults] = useState<any>([]);
  const [searchLocResults, setSearchLocResults] = useState<any>([]);
  const [toggleFlyer, settoggleFlyer] = useState<boolean>(false);
  const [noOfFiltersSelected, setNoOfFiltersSelected] = useState(0);
  const [savedSearchId, setSavedSearchId] = useState('');
  const [lastSavedKeyword, setLastSavedKeyword] = useState(
    searchedJobTitle || ''
  );
  const [lastSavedLocation, setLastSavedLocation] = useState(location || '');
  const [headTextLength, setHeadTextLength] = useState<number>();

  const categoryTitle = props.categoryDetails?.categoryTitle || '';
  const showPopover = () => {
    props.hideDetails && props.hideDetails();
    if (!toggleStates.popover) {
      setToggleStates((prevState) => ({
        ...prevState,
        popover: true,
      }));
    }
  };

  const stringLen = useRef<HTMLHeadingElement>(null);

  const escClickClose = () => {
    props.hideDetails && props.hideDetails();
    if (toggleStates.popover) {
      setToggleStates((prevState) => ({
        ...prevState,
        popover: false,
      }));
    }
  };

  const hidePopover = (e: any) => {
    if (
      toggleStates.popover &&
      !e.target.classList.contains('fa-info-circle')
    ) {
      setToggleStates((prevState) => ({
        ...prevState,
        popover: false,
      }));
    }
  };

  const handleActiveJobs = (type: ClickOption) => {
    setActiveJob(type);
    props.hideDetails && props.hideDetails();
    jobSearchResultsInteractions(Action.Clicked, type);
    const existingQueryParams = mapQueryParams(router.query);
    if (type == 'date') {
      existingQueryParams['sortbyposteddate'] = ['true'];
    }
    if (type == 'relevance') {
      delete existingQueryParams['sortbyposteddate'];
    }
    existingQueryParams['page'] = ['1'];
    if (existingQueryParams.savedsearch) {
      const savedSearchId = decodeCompleteEncodedString(
        extractActualSavedSearchValue(router.asPath) as string
      );
      existingQueryParams.savedsearch = [savedSearchId];
    }
    let queryString: string = queryStringManipulation(existingQueryParams);
    document.location.href =
      window.location.pathname + queryString.slice(0, -1);
  };

  useEffect(() => {
    if (toggleStates.popover) {
      document.addEventListener('click', hidePopover);
    } else {
      document.removeEventListener('click', hidePopover);
    }
    return () => {
      document.removeEventListener('click', hidePopover);
    };
  });

  const savePopupRef = useRef<HTMLDivElement | null>(null);

  const isOutsideClick = useOutsideClick(savePopupRef);
  useEffect(() => {
    if (isOutsideClick) {
      setSavePopup(false);
    }
  }, [isOutsideClick]);

  const getQueryString = (
    item: Object,
    prefix?: string,
    keepApmersand?: boolean
  ): string => {
    let queryUrl: string = '';
    const keysNotToPrefix = [
      'createdAt',
      'updatedAt',
      'searchID',
      'name',
      'emailAlert',
      'pushNotification',
    ];
    if (item && Object.entries(item).length > 0) {
      Object.entries(item).forEach(
        ([key, value]: [string, string | Array<string> | boolean]) => {
          if (Array.isArray(value) && value.length > 0) {
            value.map((item: string) => {
              queryUrl =
                queryUrl +
                `${
                  prefix ? (keysNotToPrefix.includes(key) ? '' : prefix) : ''
                }${key}=${encodeURIComponent(item)}&`;
            });
          } else if (
            key !== 'searchkeyword' &&
            typeof value === 'string' &&
            value !== ''
          ) {
            queryUrl =
              queryUrl +
              `${
                prefix ? (keysNotToPrefix.includes(key) ? '' : prefix) : ''
              }${key}=${encodeURIComponent(value)}&`;
          }
        }
      );
    }
    queryUrl = `?${keepApmersand ? queryUrl : queryUrl.slice(0, -1)}`;
    return queryUrl;
  };

  const updateSaveSearch = async () => {
    if (savedSearchId) {
      const decodedSearchID = decodeCompleteEncodedString(savedSearchId);
      const response = await getSavedSearchById(
        encodeURIComponent(decodedSearchID),
        document.cookie
      );
      const name = response.data.name;
      const emailAlert = response.data.emailAlert;
      const saveSearchReq: any = {};
      for (const key in jobSearchFilters) {
        if (key.toLowerCase() in props.queryParams) {
          saveSearchReq[jobSearchFilters[key]] = props.queryParams[key];
        }
      }
      if (inputParam.searchkeyword || inputParam.selectedKeyword) {
        saveSearchReq.searchKeyword =
          inputParam.searchkeyword || inputParam.selectedKeyword;
      }

      if (inputParam.location && inputParam.selectedLocation) {
        saveSearchReq.joblocations = [inputParam.selectedLocation];
        saveSearchReq['loc.latlng'] = [inputParam['latLong']];
        saveSearchReq['loc.radius'] = ['30'];
      } else if (inputParam.location && !inputParam.selectedLocation) {
        const isLocationFormDirty = inputParam.location !== lastSavedLocation;
        saveSearchReq.joblocations = isLocationFormDirty
          ? [inputParam.location]
          : selectedFilters.joblocations;
        !isLocationFormDirty &&
          (saveSearchReq['loc.latlng'] = selectedFilters['loc.latlng']);
        saveSearchReq['loc.radius'] = selectedFilters['loc.radius'];
      } else if (
        !inputParam.location &&
        !inputParam.selectedLocation &&
        inputParam.preSelectedCountry
      ) {
        saveSearchReq.joblocations = selectedFilters.preSelectedCountry ?? [
          inputParam.preSelectedCountry,
        ];
        saveSearchReq['loc.latlng'] = selectedFilters['loc.latlng'];
        saveSearchReq['loc.radius'] = selectedFilters['loc.radius'];
      }
      if (props.showWorkAnywhereInUsCheckbox) {
        saveSearchReq.CandidateAnywhereInUS = [
          inputParam.isWorkAnywhereInUsChecked,
        ];
      }

      if (props.showWorkAnywhereInWorldCheckbox) {
        saveSearchReq.CandidateAnywhereInWorld = [
          inputParam.isWorkAnywhereInWorldChecked,
        ];
      }
      saveSearchReq.name = name;
      // decoding because it is already encoded, otherwise will get encoded again inside getQueryString
      saveSearchReq.searchID = decodedSearchID;
      const queryParams = getQueryString(saveSearchReq, 'Search.', true);
      const updateSearchResult = await updateSaveJobSearchApi(
        `${queryParams}emailAlert=${emailAlert}`
      );
      if (
        updateSearchResult &&
        updateSearchResult.data &&
        updateSearchResult.data.success
      ) {
        saveSearchReq.searchKeyword &&
          setLastSavedKeyword(saveSearchReq.searchKeyword);
        saveSearchReq.joblocations && setLastSavedLocation(inputParam.location);
        setToggleStates((prevState) => ({
          ...prevState,
          savedSearchButtonState: SAVED_SEARCH_BUTTON_STATE.saved,
        }));
      }
    }
  };

  const handleSaveSearch = async (
    name: string,
    emailAlert: boolean
  ): Promise<boolean> => {
    const saveSearchReq: any = {};
    for (const key in jobSearchFilters) {
      if (key.toLowerCase() in props.queryParams) {
        saveSearchReq[jobSearchFilters[key]] = props.queryParams[key];
      }
    }
    if (inputParam.searchkeyword || inputParam.selectedKeyword) {
      saveSearchReq.searchKeyword =
        inputParam.searchkeyword || inputParam.selectedKeyword;
    }

    if (inputParam.location && inputParam.selectedLocation) {
      saveSearchReq.joblocations = [inputParam.selectedLocation];
      saveSearchReq['loc.latlng'] = [inputParam['latLong']];
      saveSearchReq['loc.radius'] = ['30'];
    } else if (inputParam.location && !inputParam.selectedLocation) {
      saveSearchReq.joblocations = selectedFilters.joblocations ?? [
        inputParam.location,
      ];
      saveSearchReq['loc.latlng'] = selectedFilters['loc.latlng'];
      saveSearchReq['loc.radius'] = selectedFilters['loc.radius'];
    } else if (
      !inputParam.location &&
      !inputParam.selectedLocation &&
      inputParam.preSelectedCountry
    ) {
      saveSearchReq.joblocations = selectedFilters.preSelectedCountry ?? [
        inputParam.preSelectedCountry,
      ];
      saveSearchReq['loc.latlng'] = selectedFilters['loc.latlng'];
      saveSearchReq['loc.radius'] = selectedFilters['loc.radius'];
    }
    if (props.showWorkAnywhereInUsCheckbox) {
      saveSearchReq.CandidateAnywhereInUS = [
        inputParam.isWorkAnywhereInUsChecked,
      ];
    }

    if (props.showWorkAnywhereInWorldCheckbox) {
      saveSearchReq.CandidateAnywhereInWorld = [
        inputParam.isWorkAnywhereInWorldChecked,
      ];
    }
    saveSearchReq.name = name;

    let savedSuccessFully = false;

    try {
      const queryParams = getQueryString(saveSearchReq, undefined, true);
      const searchSaveResult = await saveJobSearchApi(
        `${queryParams}emailAlert=${emailAlert}`
      );

      if (
        searchSaveResult &&
        searchSaveResult.data &&
        searchSaveResult.data.success
      ) {
        jobSearchResultsInteractions(
          Action.Save_Search,
          undefined,
          name,
          getGuidValue(setGuid)
        );
        setToggleStates((prevState) => ({
          ...prevState,
          savedSearchButtonState: SAVED_SEARCH_BUTTON_STATE.saved,
        }));
        setSavedSearchId(searchSaveResult.data.searchId);
        saveSearchReq.searchKeyword &&
          setLastSavedKeyword(saveSearchReq.searchKeyword);
        saveSearchReq.joblocations && setLastSavedLocation(inputParam.location);
        savedSuccessFully = true;
        return savedSuccessFully;
      }
    } catch (err) {
      console.log('Error', err);
      return false;
    } finally {
      savedSuccessFully &&
        setToggleStates((prevState) => ({
          ...prevState,
          SavedSearchPopup: false,
        }));
    }
    return false;
  };

  useEffect(() => {
    if (keywordDebounce !== '' && enableFilterSearch.keyword) {
      fetchKeywordsData(keywordDebounce);
    }
    if (locationDebounce !== '' && enableFilterSearch.location) {
      fetchLocationData(locationDebounce);
    }
  }, [keywordDebounce, locationDebounce]);

  const fetchKeywordsData = async (param: string) => {
    const searchResults = await getJobTitle(param);
    if (param === searchResults.data[0]) {
      setSearchResults([]);
    } else {
      setSearchResults(searchResults?.data || []);
    }
  };

  const fetchLocationData = async (param: string) => {
    const locResults = await getLocationLookup(param);
    if (param === locResults.data[0]) {
      setSearchLocResults([]);
    } else {
      let locationData: {
        [key: string]: string;
      } = {};
      locResults.data.map((loc: LocationLookupItem) => {
        const { key, value, latlong } = modifyLocationLookupData(loc);
        locationData[key] = `${value} + ${latlong}`;
      });
      setSearchLocResults(locationData);
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputParams((prevState: any) => ({
      ...prevState,
      searchkeyword: e.target.value,
      selectedKeyword: e.target.value.trim(),
    }));
    enableFilterSearch.keyword = true;
    enableFilterSearch.location = false;
  };

  const setInputValHandler = (value: any, id: string) => {
    if (id == 'search-by-location') {
      setInputParams((prevState: any) => ({
        ...prevState,
        location: value,
      }));
      enableFilterSearch.location = true;
      enableFilterSearch.keyword = false;
    } else {
      setInputParams((prevState: any) => ({
        ...prevState,
        searchkeyword: value,
      }));
    }

    enableFilterSearch.keyword = false;
    enableFilterSearch.location = false;
  };

  const changeLocHandler = (e: any) => {
    e.preventDefault();
    setInputParams((prevState: any) => ({
      ...prevState,
      location: e.target.value,
      // selectedLocation: e.target.value.trim(),
    }));
    if (e.code === 'Enter') {
      enableFilterSearch.location = false;
      enableFilterSearch.keyword = false;
    } else {
      enableFilterSearch.location = true;
      enableFilterSearch.keyword = false;
    }
  };

  const selectSearchResult = (e: React.SyntheticEvent, result: string) => {
    e.preventDefault();
    setInputParams((prevState: any) => ({
      ...prevState,
      searchkeyword: result,
    }));
    setSearchResults([]);
    enableFilterSearch.keyword = false;
  };

  const selectLocResult = (e: React.SyntheticEvent, result: any) => {
    e.preventDefault();
    const location = searchLocResults[result].split(' + ')[0].trim();
    const latLong = searchLocResults[result].split(' + ')[1].trim();
    setInputParams((prevState: any) => ({
      ...prevState,
      location: result,
      selectedLocation: location,
      latLong: latLong.includes(null) ? '' : latLong,
    }));
    setSearchLocResults([]);
    enableFilterSearch.location = false;
  };

  const onSearchClick = (e: any) => {
    e.preventDefault();
    setIsSearchButtonPressed((prevState) => !prevState);
    props.hideDetails && props.hideDetails();
    const existingQueryParams = getManipulatedQS();
    checkIfLocationIsPreselected(existingQueryParams);

    if (!inputParam.selectedLocation && !existingQueryParams.useclocation) {
      existingQueryParams.useclocation = [true];
    }

    if (inputParam.selectedLocation || inputParam.encodedLocation) {
      // To identify wheather location is selected from dropdown or not
      // If we typed a location then selectedLocation will be empty
      //  Setting this value from checkIfLocationIsPreselected
      delete existingQueryParams.useclocation;
    }
    delete existingQueryParams['page'];
    delete selectedFilters['page'];

    if (e.key === 'Enter' && e.target.id !== 'search-by-location') {
      existingQueryParams.searchkeyword = [e.target.value];
    }

    const queryString: string = queryStringManipulation({
      ...selectedFilters,
      ...existingQueryParams,
    });
    // Send to search page if location filter is changed on a location page
    const locationChangedPath = checkForLocationChanged(
      queryString,
      existingQueryParams
    );

    if (locationChangedPath) {
      document.location.href = '/remote-jobs/search' + locationChangedPath;
    } else {
      document.location.href = '/remote-jobs/search' + queryString.slice(0, -1);
    }

    // event call
    if (
      (Array.isArray(existingQueryParams.searchkeyword) &&
        existingQueryParams.searchkeyword[0].length) ||
      (Array.isArray(existingQueryParams.joblocations) &&
        existingQueryParams.joblocations[0].length)
    ) {
      track('job search parameters', {
        keyword: existingQueryParams?.searchkeyword?.join(', ') || '',
        location: existingQueryParams?.joblocations?.join(', ') || '',
      });
    }
  };
  const onWorkAnywhereChange = (checkboxName: string) => {
    // Hide details if props.hideDetails is defined
    if (props.hideDetails) {
      props.hideDetails();
    }

    // Delete the 'page' parameter from selectedFilters
    delete selectedFilters['page'];

    let queryString: string = '';
    let usChecked = inputParam.isWorkAnywhereInUsChecked;
    let worldChecked = inputParam.isWorkAnywhereInWorldChecked;

    // Map existing query parameters
    const existingQueryParams = mapQueryParams(router.query);

    // Adjust savedsearch parameter if it exists
    if (existingQueryParams?.savedsearch?.length) {
      existingQueryParams.savedsearch[0] = decodeURIComponent(
        extractActualSavedSearchValue(router.asPath) as string
      );
    }

    // Handle checkbox changes and update state
    if (checkboxName.toLowerCase() === 'us') {
      usChecked = !inputParam.isWorkAnywhereInUsChecked;
      setInputParams({
        ...inputParam,
        isWorkAnywhereInUsChecked: usChecked,
      });
      existingQueryParams['anywhereinus'] = usChecked ? ['1'] : ['0'];
    } else {
      worldChecked = !inputParam.isWorkAnywhereInWorldChecked;
      setInputParams({
        ...inputParam,
        isWorkAnywhereInWorldChecked: worldChecked,
      });
      existingQueryParams['anywhereinworld'] = worldChecked ? ['1'] : ['0'];
    }

    // Ensure 'page' is removed from existingQueryParams
    delete existingQueryParams['page'];

    queryString = queryStringManipulation(existingQueryParams);

    document.location.href =
      window.location.pathname + queryString.slice(0, -1);
  };

  const handleFlyer = () => {
    settoggleFlyer(!toggleFlyer);
  };

  const handleSaveSearchBtnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    props.hideDetails && props.hideDetails();
    setToggleStates((prevState) => ({
      ...prevState,
      SavedSearchPopup: true,
    }));
  };

  const shouldButtonBeDisabled = () => {
    const isSearchSaved =
      toggleStates.savedSearchButtonState === SAVED_SEARCH_BUTTON_STATE.saved;
    const isKeywordSame =
      inputParam.searchkeyword === lastSavedKeyword ||
      inputParam.selectedKeyword === lastSavedKeyword;
    const isLocationSame = inputParam.location === lastSavedLocation;

    return isSearchSaved && isKeywordSame && isLocationSame;
  };

  const checkIfLocationIsPreselected = (existingQueryParams: any) => {
    // ! NOTE: If we have selected any location from dropdown and then try
    // click search button without typing anything in location input field
    // then we preserve value of joblocaions and latlng in query params
    if (
      existingQueryParams.joblocations &&
      inputParam.encodedLocation &&
      !inputParam.selectedLocation
    ) {
      let { locationObj }: any = modifySelectedLocation(
        inputParam.encodedLocation,
        countyList
      );
      let selectedLocation = `${locationObj.name}${
        locationObj.stateName !== '' && locationObj.stateName !== '@@@'
          ? ', ' + locationObj.stateName
          : ''
      }`;

      if (selectedLocation != inputParam.location) {
        existingQueryParams.usecLocation = [true];
      }

      if (selectedLocation === inputParam.location) {
        existingQueryParams.joblocations = [inputParam.encodedLocation];
        existingQueryParams['loc.latlng'] = [inputParam.latLong];
      } else {
        // existingQueryParams.joblocations = [inputParam.location];
        existingQueryParams.joblocations = [
          existingQueryParams.joblocations[0],
        ];
        existingQueryParams['loc.latlng'] = ['0,0'];
      }
      if (
        inputParam.country &&
        inputParam.preSelectedCountry !== inputParam.country
      ) {
        // ! To handle case when country change from country dropdown
        existingQueryParams.joblocations = [`${inputParam.country}`];
        existingQueryParams['loc.latlng'] = ['0,0'];
      }
    }
  };

  useEffect(() => {
    const mergedObject: any = {
      ...selectedFilters,
      ...router.query,
    };
    if (router.query.savedsearch)
      setSavedSearchId(extractActualSavedSearchValue(router.asPath) as string);
    /**
     * Use Case : Merging existing query params with selected filters to avoid duplicate
     *  1. There are pages where we need filters to be selected by default.
     *  2. If we unselect and select the same using checkbox, it will be added twice in object. One in Query and other in selectedFilters.
     *  3. To avoid this, we are merging both the objects and removing duplicates.
     */
    let query: any = mapQueryParams(router.query);

    if (query.joblocations) {
      let itemWithComma = query.joblocations.filter(
        (item: any) => decodeCompleteEncodedString(item).split(',').length == 4
      );
      setInputParams((prevState: any) => ({
        ...prevState,
        encodedLocation: itemWithComma[0] || '',
        latLong: query['loc.latlng']?.[0] || '',
      }));
    }

    if (query.searchkeyword) {
      setClientSettings({
        key: _CONSTANTS.LAST_SEARCH_TERM,
        value: query.searchkeyword[0],
      });
      track('identify', {
        lastSearchTerm: query.searchkeyword[0],
        email: JSON.parse(claims)?.email || '',
      });
      // for ecom exp: FJ-2322
      updateSearchActivity({ lastSearchTerm: query.searchkeyword[0] });
    }

    for (const key in query) {
      if (selectedFilters[key]) {
        const isMultipleSelected = Array.isArray(selectedFilters[key])
          ? [...selectedFilters[key]]
          : [selectedFilters[key]];
        mergedObject[key] = Array.from(
          new Set([...query[key], ...isMultipleSelected])
        );
      }
    }
    const existingQueryParams = mapQueryParams(mergedObject);
    setSelectedFilters(existingQueryParams);
    selectedFiltersBeforeLoad(existingQueryParams);
    if (
      existingQueryParams.sortbyposteddate &&
      existingQueryParams.sortbyposteddate[0] == 'true'
    ) {
      setActiveJob('date');
    }
    if (deviceType == 'mobile') {
      let filterCountObj = JSON.parse(JSON.stringify(existingQueryParams));
      filterCountObj = removeItemsFromFilterObj(
        [
          'joblocations',
          'anywhereinworld',
          'anywhereinus',
          'loc.latlng',
          'loc.radius',
          'searchkeyword',
          'useclocation',
          'queryval',
          'savedsearch',
        ],
        filterCountObj
      );
      let count: any = 0;
      for (let key in filterCountObj) {
        if (key === 'categories') {
          filterCountObj[key].map((item: any) => {
            count =
              count +
              getTotalSubcategoriesAndCategory([item], props.jobFilters);
          });
        } else {
          count = count + filterCountObj[key].length;
        }
      }
      setNoOfFiltersSelected(count);
    }

    if (props.queryParams.categories || router.query.categories) {
      const idOfSelectedCategory = [];

      // router has all the selected categories so do not read from queryparams if router has categories
      if (Array.isArray(router.query.categories))
        idOfSelectedCategory.push(...router.query.categories);
      else if (router.query.categories)
        idOfSelectedCategory.push(router.query.categories);
      else if (Array.isArray(props.queryParams.categories))
        idOfSelectedCategory.push(...props.queryParams.categories);
      else if (props.queryParams.categories)
        idOfSelectedCategory.push(props.queryParams.categories);

      const selectedCategory = mapIdTONameOfCategories(
        jobsData,
        idOfSelectedCategory
      );
      mergedObject.categories = selectedCategory;
    }

    if (location) {
      mergedObject.joblocations = location;
    } else if (props.isForLocation) {
      mergedObject.joblocations = props.categoryDetails.categoryTitle;
    }

    if (!router.query.page || router.query.page === '1') {
      jobSearchPerformedTrackEvent(
        getSearchType(),
        mergedObject,
        inputParam.searchkeyword || inputParam.selectedKeyword,
        jobsData?.jobs?.totalCount,
        getGuidValue(setGuid)
      );
    }
    let queryString: string = queryStringManipulation(existingQueryParams);
    setFilterQs(queryString.slice(0, -1));
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' ||
        event.key === 'Esc' ||
        event.keyCode === 27
      ) {
        escClickClose();
      }
    };
    window.document.addEventListener('keydown', handleKeyDown);

    return () => {
      window.document.removeEventListener('keydown', handleKeyDown);
    };
  }, [escClickClose]);

  useEffect(() => {
    let { cookies } = checkCookieHeaders(document.cookie);
    const cookieValue =
      (cookies.UserStatus &&
        (JSON.parse(cookies.UserStatus) as ILoggedInUser)) ||
      '';
    if (cookieValue.IsUserLoggedIn && checkIsFJFeatureAllowed()) {
      pageView({
        id: 0,
        member_Id: cookieValue ? Number(cookieValue.User.MemberId) : 0,
        pageKind: 3,
        jobId: props.paginationData?.currentPage ?? 0,
        pageResult: props.paginationData?.totalCount ?? 0,
        session_Id: cookies._session_id ?? '',
        annotation: inputParam.selectedKeyword.slice(0, 68),
        device: deviceType === 'mobile' ? 0 : 1,
      });
    }
  }, []);

  const handleFilterChange = (
    filterName: string,
    filterCategory: string,
    name: string
  ) => {
    checkIfLocationIsPreselected(selectedFilters);
    if (isFunctionCalled && deviceType !== 'mobile') {
      // ! Disallow to call function multiple times
      let queryString: string = queryStringManipulation(selectedFilters);
      return window.location.pathname + queryString.slice(0, -1);
    }
    setIsFunctionCalled(true);
    jobSearchResultsInteractions(Action.Clicked, name.toLowerCase());
    delete selectedFilters['page'];
    const preSelectedFilters = selectedFilters;
    let tempArr: SelectedFiltersType = {};

    if (
      preSelectedFilters[filterName] &&
      preSelectedFilters[filterName].includes(filterCategory)
    ) {
      preSelectedFilters[filterName] = preSelectedFilters[filterName].filter(
        (item) => item !== filterCategory
      );
      tempArr = preSelectedFilters;
    } else {
      tempArr = {
        ...preSelectedFilters,
        [filterName]: preSelectedFilters[filterName]
          ? [...preSelectedFilters[filterName], filterCategory]
          : [filterCategory],
      };
    }

    // Allow only one item at a time
    if (
      ['educations', 'companies'].includes(filterName) &&
      tempArr[filterName].length > 1
    ) {
      tempArr[filterName] = [filterCategory];
    }
    setSelectedFilters(tempArr);
    tempArr = addLocationAndKeyword(tempArr);
    if (
      toggleStates.savedSearchButtonState === SAVED_SEARCH_BUTTON_STATE.saved
    ) {
      tempArr.savedsearch = [decodeCompleteEncodedString(savedSearchId)];
    }

    let existingQueryParams = getManipulatedQS();
    if (!inputParam.selectedLocation && !existingQueryParams.useclocation) {
      existingQueryParams.useclocation = [true];
    }

    if (inputParam.selectedLocation || inputParam.encodedLocation) {
      // To identify wheather location is selected from dropdown or not
      // If we typed a location then selectedLocation will be empty
      //  Setting this value from checkIfLocationIsPreselected
      delete existingQueryParams.useclocation;
    }
    const mergedObject = { ...existingQueryParams, ...tempArr };

    let queryString: string = queryStringManipulation(mergedObject);
    setFilterQs(queryString.slice(0, -1));

    const locationChangedPath = checkForLocationChanged(
      queryString,
      mergedObject
    );

    if (isRemoteJobsPage) {
      return locationChangedPath
        ? (document.location.href =
            '/remote-jobs/search' + locationChangedPath.slice(0, -1))
        : (document.location.href =
            '/remote-jobs/search' + queryString.slice(0, -1));
    }

    if (locationChangedPath) return locationChangedPath;
    return window.location.pathname + queryString.slice(0, -1);
  };

  const removeFilter = (slug: string) => {
    jobSearchResultsInteractions(Action.Clicked, ClickOption.Clear);
    delete selectedFilters[slug];
    setSelectedFilters({ ...selectedFilters });
    if (
      toggleStates.savedSearchButtonState === SAVED_SEARCH_BUTTON_STATE.saved
    ) {
      selectedFilters.savedsearch = [
        decodeCompleteEncodedString(savedSearchId),
      ];
    }
    let queryString: string = queryStringManipulation(selectedFilters);
    // There are pages like category | full-time | part-time where we have preselected filters and if we try to remove that filters its redirect to search page.
    if (isCategoryJobsPage && !selectedFilters.categories) {
      document.location.href = '/remote-jobs/search' + queryString.slice(0, -1);
      return;
    }
    document.location.href =
      window.location.pathname + queryString.slice(0, -1);
  };

  const addLocationAndKeyword = (tempArr: any) => {
    if (inputParam.location && !inputParam.selectedLocation) {
      let locArr =
        (inputParam.encodedLocation && inputParam.encodedLocation.split(',')) ||
        (inputParam.location && inputParam.location.split(','));
      if (inputParam.preSelectedCountry) {
        // ! Fix FJ-1700
        let locModified =
          locArr.length == 2
            ? `${locArr[0]}, ,${locArr[1]},  ${inputParam.preSelectedCountry}`
            : inputParam.location;
        tempArr.joblocations =
          decodeCompleteEncodedString(inputParam.encodedLocation) ||
          locModified;
      } else {
        tempArr.joblocations = inputParam.location;
      }
    } else if (inputParam.selectedLocation) {
      // When we select value from dropdown
      tempArr.joblocations = [inputParam.selectedLocation];
    }
    tempArr.searchkeyword = [
      inputParam.searchkeyword || inputParam.selectedKeyword,
    ];

    if (
      !inputParam.selectedLocation &&
      !inputParam.location &&
      !inputParam.encodedLocation
    ) {
      delete tempArr.joblocations;
    }

    if (tempArr.joblocations && tempArr.joblocations[0]) {
      tempArr['loc.latlng'] = [inputParam.latLong || '0,0'];
      tempArr['loc.radius'] = ['30'];
    }
    return tempArr;
  };

  // ! To handle category dropdown
  const handleCategoryFilter = (
    filter: any,
    categoryId: string,
    isCategoryType: 'Parent' | 'Child'
  ) => {
    jobSearchResultsInteractions(Action.Clicked, ClickOption.Category);
    let tempArr: any = selectedFilters;

    tempArr = addLocationAndKeyword(tempArr);
    if (tempArr?.savedsearch?.length)
      tempArr.savedsearch[0] = decodeCompleteEncodedString(
        extractActualSavedSearchValue(router.asPath) as string
      );

    if (Object.keys(selectedFilters).length === 0) {
      // If no filter is selected
      tempArr.categories = [categoryId];
      let queryString: string = queryStringManipulation(tempArr);
      document.location.href =
        window.location.pathname + queryString.slice(0, -1);
      return;
    } else {
      if (isCategoryType === 'Parent') {
        // Check if category is already selected
        if (
          tempArr.categories &&
          tempArr.categories.includes(JSON.stringify(filter.category.id))
        ) {
          // If selected then remove from url and add all childs in url
          let categoryAfterFilter = tempArr.categories.filter(
            (item: any) => ![categoryId].includes(item)
          );
          // Adding Sub-Categories of Removed Parent Category
          let subCategoryOfParentCategory = filter.subCategory.map(
            (i: any) => i.id
          );

          tempArr.categories = [
            ...subCategoryOfParentCategory,
            ...categoryAfterFilter,
          ];
        } else {
          // Removing Sub Categories of Selected Parent Category
          tempArr.categories =
            tempArr.categories &&
            tempArr.categories.filter(
              (item: any) =>
                !filter.subCategory.some((subItem: any) => subItem.id == item)
            );
          // If not selected then add in url
          tempArr.categories = tempArr.categories
            ? [...tempArr.categories, categoryId]
            : [categoryId];
        }
      }

      if (isCategoryType === 'Child') {
        // Check if category is already selected

        // ! If parent Id is present won't allow to add child id in array
        if (
          tempArr.categories &&
          !tempArr.categories.includes(String(filter.category.id)) &&
          !tempArr.categories.includes(categoryId)
        ) {
          tempArr.categories = tempArr.categories
            ? [...tempArr.categories, categoryId]
            : [categoryId];
        } else if (
          tempArr.categories &&
          tempArr.categories.includes(categoryId)
        ) {
          // If selected then remove from url
          tempArr.categories = tempArr.categories.filter(
            (item: any) => ![categoryId].includes(item)
          );
        } else if (!tempArr.categories) {
          tempArr.categories = tempArr.categories
            ? [...tempArr.categories, categoryId]
            : [categoryId];
        }
      }

      let queryString: string = queryStringManipulation(tempArr);

      if (isRemoteJobsPage) {
        return (document.location.href =
          '/remote-jobs/search' + queryString.slice(0, -1));
      }
      document.location.href =
        window.location.pathname + queryString.slice(0, -1);
    }
  };

  const checkForLocationChanged = (
    queryString: string,
    existingQueryParams: any
  ) => {
    if (
      inputParam.location?.toLowerCase() !== props.location?.toLowerCase() ||
      inputParam.country ||
      inputParam.preSelectedCountry
    ) {
      if (
        !inputParam.selectedLocation &&
        !inputParam.location &&
        (inputParam.country || inputParam.preSelectedCountry)
      ) {
        // existingQueryParams.joblocations = [
        //   `null, null, @@@, ${
        //     inputParam.country || inputParam.preSelectedCountry
        //   }`,
        // ];

        existingQueryParams.joblocations = [
          `${inputParam.country || inputParam.preSelectedCountry}`,
        ];
        queryString = queryStringManipulation(existingQueryParams);
      }
      return props.openSearchOnLocationChange
        ? '/search' + queryString.slice(0, -1)
        : queryString.slice(0, -1);
    }
  };

  const checkLocationForUSOrWorld = (
    existingQueryParams: any,
    jobLocation: string
  ) => {
    /**
     * JobLocations is used for showing location in input field
     * As we are not passing 4th param in joblocation we need to pass specifly anywhereinworld or anywhereinus
     * if we passed the 4th param in joblocation it will start showing country field
     */
    if (US_GLOBAL_LOCATION.US_LOC.includes(jobLocation)) {
      delete existingQueryParams.anywhereinworld;
      existingQueryParams.joblocations = [`${jobLocation}, null, @@@, `];
      existingQueryParams['anywhereinus'] = ['1'];
      return existingQueryParams;
    }
    if (US_GLOBAL_LOCATION.GLOBAL_LOC.includes(jobLocation)) {
      delete existingQueryParams.anywhereinus;
      existingQueryParams.joblocations = [`${jobLocation}, null, @@@, `];
      existingQueryParams['anywhereinworld'] = ['1'];
      return existingQueryParams;
    }
    return null;
  };

  const getManipulatedQS = () => {
    let existingQueryParams = mapQueryParams(
      isOnlySavedSearchQueryParam() ? selectedFilters : router.query
    );
    delete existingQueryParams['page'];
    existingQueryParams.searchkeyword = [
      inputParam.searchkeyword || inputParam.selectedKeyword,
    ];

    let jobLoc = inputParam.location
      ? inputParam.location
      : inputParam.preSelectedCountry
      ? inputParam.encodedLocation || inputParam.preSelectedCountry
      : '';
    let isUSorGlobal = checkLocationForUSOrWorld(
      existingQueryParams,
      inputParam.location
    );
    if (isUSorGlobal) {
      /**
       *  If inputParam.location is (United States, US, USA, U.S. , U.S.A., U.S)
       *  Set -> anywhereinus=1
       *  If inputParam.location is (Anywhere, Global, World)
       *  Set -> anywhereinworld=1
       */
      existingQueryParams = isUSorGlobal;
    } else if (jobLoc || inputParam.selectedLocation) {
      // ! Decoding Selected location here because it's again going to encode in queryStringManipulation
      // ! We are encoding location two times (Response from lookup)
      // let createCountryFormat = undefined;
      // let jobLocationLength = jobLoc.split(',').length || 0;
      // if (jobLocationLength <= 1 && !isUSorGlobal && countyList?.length) {
      //   createCountryFormat = countyList.find(
      //     (item) => item.countryName.toLowerCase() === jobLoc.toLowerCase()
      //   );
      // }
      // if (!createCountryFormat) {
      // eslint-disable-next-line no-debugger
      // jobLoc = jobLocationLength <= 1 ? `${jobLoc}, null, @@@, ` : jobLoc;
      existingQueryParams.joblocations = [
        decodeURIComponent(inputParam.selectedLocation) ||
          decodeURIComponent(jobLoc),
      ];
      // }
      // else {
      //   existingQueryParams.joblocations = [
      //     decodeURIComponent(
      //       `null, null, @@@, ${createCountryFormat.countryName}`
      //     ),
      //   ];
      // }
    } else {
      delete existingQueryParams.joblocations;
      delete existingQueryParams.anywhereinus;
      delete existingQueryParams.anywhereinworld;
      delete existingQueryParams['loc.latlng'];
      delete existingQueryParams['loc.radius'];
    }

    if (
      existingQueryParams.joblocations &&
      existingQueryParams.joblocations[0]
    ) {
      existingQueryParams['loc.latlng'] = [inputParam.latLong || '0,0'];
      existingQueryParams['loc.radius'] = ['30'];
    }
    /**
     * If Preselected Country and Country is differenct in inputParams
     * set location in inputParams to empty string.
     */
    if (
      inputParam.country &&
      inputParam.preSelectedCountry !== inputParam.country
    ) {
      // existingQueryParams.joblocations = [
      //   `null, null, @@@, ${inputParam.country}`,
      // ];
      existingQueryParams.joblocations = [`${inputParam.country}`];
    }
    return existingQueryParams;
  };

  const setSearchParams = (value: string) => {
    setInputParams((prevState: any) => ({
      ...prevState,
      searchkeyword: value,
    }));
  };

  const sortSelectedFilters = (obj: any) => {
    const sortedData: { [key: string]: any } = {}; // Add index signature
    selectedFiltersOrder.forEach((key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        sortedData[key] = obj[key];
      }
    });
    return sortedData;
  };

  const getCategoriesName = (
    allCategoriesData: any,
    selectedCategories: any
  ) => {
    let data = allCategoriesData.category;
    selectedCategories = Array.isArray(selectedCategories)
      ? selectedCategories
      : [selectedCategories];
    let names = [];
    for (let item of data) {
      if (selectedCategories.includes(String(item.category.id))) {
        names.push(item.category.name);
        for (let subcategory of item.subCategory) {
          names.push(subcategory.name);
        }
      } else {
        for (let subcategory of item.subCategory) {
          if (selectedCategories.includes(String(subcategory.id))) {
            names.push(subcategory.name);
          }
        }
      }
    }
    return names;
  };

  const selectedFiltersName = (selectedFilters: any) => {
    let namedFilters: any = [];
    selectedFilters = sortSelectedFilters(selectedFilters);
    Object.entries(selectedFilters).map(([keys, values]: any) => {
      if (keys === 'searchkeyword') {
        namedFilters.push(`"${values}"`);
      } else if (keys === 'categories') {
        const categoryFilter = props.jobFilters.find(
          (i) => i.slug === 'categories'
        );

        categoryFilter &&
          namedFilters.push(...getCategoriesName(categoryFilter, values));
      } else {
        namedFilters.push(values);
      }
    });
    return namedFilters;
  };

  const disableSavedSearchBtn = shouldButtonBeDisabled();

  const showSavedSearchBtn =
    props.isPremiumUser &&
    props.saveSeachButtonState !== SAVED_SEARCH_BUTTON_STATE.hide;

  const filterdKeysOnly = Object.keys(acceptOnlyFilterdKeys(filtersBeforeLoad));
  const selectedFilterName = selectedFiltersName(filtersBeforeLoad);
  const [isSearchButtonPressed, setIsSearchButtonPressed] = useState(false);

  useEffect(() => {
    if (!isPremiumUser && stringLen.current) {
      const headLength = stringLen.current.innerText.length;
      const calculatedLength = 1.9 * headLength + 8.2;
      setHeadTextLength(calculatedLength);
    }
  }, [isPremiumUser]);

  let filterString = jobCountFilters(
    filterdKeysOnly,
    selectedFilterName,
    router,
    props.selectedCountry,
    inputParam,
    location
  );

  const searchCommonFilterwrapper = () => {
    const FilterWrappercounts = () => {
      return (
        <FilterWrapper
          isRCPortal={isRCPortal}
          isOpenDetailJobId={isOpenDetailJobId}
          isPremiumUser={isPremiumUser}
          isSearchPage={isSearchPage}
          isLegitimate={isLegitimate}
        >
          <>
            {jobsData?.jobs?.totalCount > 0 && filterString && (
              <InnerWrap id="result-search-count">
                <span>
                  <>
                    {`${numFormat.format(
                      (parseInt(props.paginationData.currentPage) - 1) * 50 + 1
                    )} to ${numFormat.format(
                      Math.min(
                        parseInt(props.paginationData.currentPage) * 50,
                        parseInt(props.paginationData.totalCount)
                      )
                    )} of ${numFormat.format(
                      props.paginationData.totalCount
                    )}`}{' '}
                    {'for '}
                  </>
                </span>
                <span>{filterString}</span>
              </InnerWrap>
            )}
          </>
          {searchedJobTitle && (
            <JobTypeWrapper isRCPortal={isRCPortal}>
              <FilterButtonWrap
                isActive={activeJob === 'date'}
                isRCPortal={isRCPortal}
              >
                <Button
                  text="Date"
                  bdrRadius={'0'}
                  ButtonClass="job-filter-btn show-content"
                  clickHandler={() => {
                    handleActiveJobs(ClickOption.Date);
                  }}
                  buttonSize="small"
                  ariaLabel="Date"
                ></Button>
              </FilterButtonWrap>
              <FilterButtonWrap
                isActive={activeJob === 'relevance'}
                isRCPortal={isRCPortal}
              >
                <Button
                  ButtonClass="job-filter-btn show-content"
                  bdrRadius={'0'}
                  text="Relevance"
                  clickHandler={() => {
                    handleActiveJobs(ClickOption.Relevance);
                  }}
                  buttonSize="small"
                  ariaLabel="Relevance"
                ></Button>
              </FilterButtonWrap>
            </JobTypeWrapper>
          )}
        </FilterWrapper>
      );
    };
    return (
      <React.Fragment>
        {props.paginationData.totalCount > 0 && (
          <SearchResultsWrapper isRCPortal={isRCPortal}>
            <ResultWrapper>
              {props.pageLocation == 'locationPage' ? (
                <>
                  <HeadingWrapper islocation>
                    <ResultHeading
                      islocation
                      id="work-location-job"
                      ref={stringLen}
                    >
                      {readValueUsingKey(countrySubTitlesArr, categoryTitle)
                        ? readValueUsingKey(countrySubTitlesArr, categoryTitle)
                        : `Work from ${categoryTitle} Jobs${' '}`}
                      {/* <InfoIconWrapper id="work-loc-info">
                      <InfoIcon
                        aria-label="Show job guide"
                        className="fa fa-info-circle"
                        onClick={showPopover}
                      ></InfoIcon>
                      <JobGuidePopover
                        toggleStatus={toggleStates.popover}
                        mobTransform="-48%, 0%"
                      />
                    </InfoIconWrapper> */}
                    </ResultHeading>
                    <InfoPopOver
                      id={'work-loc-info'}
                      onClick={showPopover}
                      toggleStatus={toggleStates.popover}
                      mobTransform={
                        isPremiumUser
                          ? '-57%, 0'
                          : headTextLength
                          ? `-${headTextLength}%, 0%`
                          : ''
                      }
                    />
                  </HeadingWrapper>
                </>
              ) : (
                <>
                  {isRCPortal ? (
                    !isRemoteJobsPage &&
                    props.paginationData.totalCount > 0 && (
                      <>
                        <HeadingWrapper isRCPortal={isRCPortal}>
                          {props.isSearchPage ? (
                            <SearchResultHeading
                              id="new-job-head"
                              className="show-content"
                              isRCPortal={isRCPortal}
                            >
                              {props.localization.job_icon_guide_heading ||
                                'New Jobs'}
                            </SearchResultHeading>
                          ) : (
                            !isRemoteJobsPage && (
                              <ResultHeading
                                id="new-job-head"
                                className="show-content"
                                isRCPortal={isRCPortal}
                              >
                                {props.localization.job_icon_guide_heading ||
                                  'New Jobs'}
                              </ResultHeading>
                            )
                          )}
                          {/* <InfoPopOver
                            id={'newjob-head-info'}
                            onClick={showPopover}
                            toggleStatus={toggleStates.popover}
                          /> */}
                          {/* <InfoIconWrapper id="newjob-head-info">
                        <InfoIcon
                          aria-label="Show job guide"
                          className="fa fa-info-circle"
                          onClick={showPopover}
                        ></InfoIcon>
                        <JobGuidePopover
                          toggleStatus={toggleStates.popover}
                          mobTransform="-48%, 0%"
                        />
                      </InfoIconWrapper> */}
                        </HeadingWrapper>
                      </>
                    )
                  ) : (
                    <>
                      {props.paginationData.totalCount > 0 &&
                        deviceType !== 'mobile' && (
                          <>
                            <HeadingWrapper>
                              {props.isSearchPage ? (
                                <SearchResultHeading
                                  id="new-job-head"
                                  className="show-content"
                                >
                                  {props.localization.job_icon_guide_heading ||
                                    'New Jobs'}
                                </SearchResultHeading>
                              ) : (
                                !isRemoteJobsPage && (
                                  <ResultHeading
                                    id="new-job-head"
                                    className="show-content"
                                  >
                                    {props.localization
                                      .job_icon_guide_heading || 'New Jobs'}
                                  </ResultHeading>
                                )
                              )}
                              {/* <InfoPopOver
                                id={'newjob-head-info'}
                                onClick={showPopover}
                                toggleStatus={toggleStates.popover}
                              /> */}
                              {/* <InfoIconWrapper id="newjob-head-info">
                            <InfoIcon
                              aria-label="Show job guide"
                              className="fa fa-info-circle"
                              onClick={showPopover}
                            ></InfoIcon>
                            <JobGuidePopover
                              toggleStatus={toggleStates.popover}
                              mobTransform="-48%, 0%"
                            />
                          </InfoIconWrapper> */}
                            </HeadingWrapper>
                          </>
                        )}
                    </>
                  )}
                </>
              )}
            </ResultWrapper>
            {showSavedSearchBtn && !isRemoteJobsPage && (
              <SaveBtnWrap
                aria-label="Button group with nested dropdown"
                isRCPortal={isRCPortal}
              >
                {toggleStates.savedSearchButtonState ===
                SAVED_SEARCH_BUTTON_STATE.save_search ? (
                  <Button
                    btnType="button"
                    underlineOnHover
                    buttonType={
                      deviceType === 'desktop' ? 'primary' : 'tertiary'
                    }
                    icon={isRCPortal ? `` : 'fa-regular fa-heart'}
                    text="Save Search"
                    clickHandler={handleSaveSearchBtnClick}
                    url="#"
                    buttonSize="sm"
                    id="save-search-lg"
                    ButtonClass="show-content"
                  />
                ) : (
                  <>
                    <Button
                      btnType="button"
                      underlineOnHover
                      buttonType={
                        deviceType === 'desktop' ? 'primary' : 'tertiary'
                      }
                      text="Save Changes"
                      clickHandler={updateSaveSearch}
                      buttonSize="sm"
                      id="save-search-lg"
                      ButtonClass="save_search  show-content"
                      isDisabled={disableSavedSearchBtn}
                    />
                    <Button
                      buttonType={isRCPortal ? 'transparent' : 'secondary'}
                      icon={
                        isRCPortal
                          ? 'fas fa-angle-down'
                          : 'fa-solid fa-caret-down'
                      }
                      isIconRight
                      buttonSize="sm"
                      ButtonClass="saved_search_drop"
                      isDisabled={disableSavedSearchBtn}
                      clickHandler={() => {
                        setSavePopup((prevState) => !prevState);
                      }}
                    />
                    {savePopup && (
                      <SavePopup
                        role="popover"
                        ref={savePopupRef}
                        isRCPortal={isRCPortal}
                      >
                        <SaveLinks
                          onClick={() => {
                            setSavePopup(false);
                            setToggleStates((prevState) => ({
                              ...prevState,
                              SavedSearchPopup: true,
                            }));
                          }}
                        >
                          Save as New Search
                        </SaveLinks>
                      </SavePopup>
                    )}
                  </>
                )}
              </SaveBtnWrap>
            )}

            {toggleStates.SavedSearchPopup && (
              <SaveSearchModal
                handleCloseModal={() =>
                  setToggleStates((prevState) => ({
                    ...prevState,
                    SavedSearchPopup: false,
                  }))
                }
                handleSaveSearch={handleSaveSearch}
                searchKeyword={
                  inputParam.searchkeyword || inputParam.selectedKeyword
                }
                location={inputParam.location}
              />
            )}
          </SearchResultsWrapper>
        )}
        {isRCPortal
          ? !isRemoteJobsPage && FilterWrappercounts()
          : FilterWrappercounts()}
      </React.Fragment>
    );
  };
  const toolTips = () => {
    return (
      <React.Fragment>
        <ToolTipSection
          tooltipWidth={'80rem'}
          role="menu"
          hideContent={props.hideDetails}
          hideCloseBtn
        >
          <TipsText className="show-content">Tips</TipsText>
          <>
            <TipsHeader>
              You can further narrow your searches using these operators
              (symbols) in the keywords text field:
            </TipsHeader>
            <ListWrap>
              <ListItem>
                <TipsTitle>
                  <IconWrap>+</IconWrap>
                  plus, for AND
                </TipsTitle>
                <TipsDesc>
                  e.g., manager + director means return search results that
                  include both the terms &apos;manager&apos; AND
                  &apos;director&apos;
                </TipsDesc>
              </ListItem>
              <ListItem>
                <TipsTitle>
                  <IconWrap>|</IconWrap>
                  pipe, for OR
                </TipsTitle>
                <TipsDesc>
                  e.g., manager | director means return search results that
                  include either of the terms &apos;manager&apos; OR
                  &apos;director&apos;, but both are not required
                </TipsDesc>
              </ListItem>
              <ListItem>
                <TipsTitle>
                  <IconWrap>-</IconWrap>
                  dash, for NOT
                </TipsTitle>
                <TipsDesc>
                  e.g., manager -director means return search results for the
                  term &apos;manager&apos; but NOT when the term
                  &apos;director&apos; is present. Remember the dash must have a
                  space before it, but none before the term you want to filter
                  out.
                </TipsDesc>
              </ListItem>
              <ListItem>
                <TipsTitle>
                  <IconWrap>&quot;</IconWrap>
                  quotes, for EXACT
                </TipsTitle>
                <TipsDesc>
                  e.g., return search results only for the EXACT phrase
                  “managing director”
                </TipsDesc>
              </ListItem>
              <ListItem>
                <TipsTitle>
                  <IconWrap>*</IconWrap>
                  star, for a WILDCARD extension
                </TipsTitle>
                <TipsDesc>
                  e.g., manage* means return search results for any word
                  starting with &apos;manage&apos; such as manage, manager and
                  management
                </TipsDesc>
              </ListItem>
            </ListWrap>
          </>
        </ToolTipSection>
      </React.Fragment>
    );
  };
  const FiltersType = () => {
    return (
      <>
        {deviceType !== 'mobile' ? (
          <Filter
            removeFilter={removeFilter}
            handeleFilterChange={handleFilterChange}
            handleCategoryFilter={handleCategoryFilter}
            jobFilters={props.jobFilters}
            selectedFilters={selectedFilters}
            showAllDropFilters={props.showAllDropFilters}
            hideDetails={props.hideDetails}
            isRCPortal={isRCPortal}
          />
        ) : (
          <MbFilterWrap
            isRCPortal={isRCPortal}
            noOfFiltersSelected={noOfFiltersSelected}
          >
            <Button
              text={`Filters ${
                noOfFiltersSelected ? '(' + noOfFiltersSelected + ')' : ''
              }`}
              clickHandler={handleFlyer}
              icon="fas fa-sliders"
              buttonType={noOfFiltersSelected ? 'primary' : 'secondary'}
              id="flyer-mobile-filter"
              buttonSize="sm"
              ButtonClass="mobilefilterbtn"
            />
            {!isRCPortal && (
              <MbWorkAnywhereWrap>
                {(props.showWorkAnywhereInUsCheckbox ||
                  props.showWorkAnywhereInWorldCheckbox) && (
                  <span>Work anywhere in:</span>
                )}
                {props.showWorkAnywhereInUsCheckbox && (
                  <>
                    <input
                      type="checkbox"
                      id="work-in-us"
                      className="show-content"
                      checked={inputParam.isWorkAnywhereInUsChecked}
                      onClick={() => onWorkAnywhereChange('us')}
                    />
                    <label htmlFor="work-in-us">U.S</label>
                  </>
                )}
                {props.showWorkAnywhereInWorldCheckbox && (
                  <>
                    <input
                      type="checkbox"
                      id="world"
                      checked={inputParam.isWorkAnywhereInWorldChecked}
                      onClick={() => onWorkAnywhereChange('world')}
                    />
                    <label htmlFor="world">World</label>
                  </>
                )}
              </MbWorkAnywhereWrap>
            )}
          </MbFilterWrap>
        )}
      </>
    );
  };
  const remoteWorkTips = () => {
    return (
      <>
        {isRemoteOptionWithoutLoc ? (
          <RemoteWorkTip>
            <span>
              We recommend you enter a location since many remote jobs have
              city, state or country requirements.
            </span>
          </RemoteWorkTip>
        ) : (
          ''
        )}
      </>
    );
  };
  return (
    <>
      <Wrapper
        ref={mainWrapperRef}
        isRCPortal={isRCPortal}
        isCategoryPage={isCategoryPage}
      >
        {isRCPortal ? (
          <>
            <StyledContainerMd isPrimaryContainer={isRCPortal}>
              <SearchFilter
                isSearchButtonPressed={isSearchButtonPressed}
                locParam={inputParam.location}
                searchParam={
                  inputParam.searchkeyword || inputParam.selectedKeyword
                }
                changeHandler={changeHandler}
                changeLocHandler={changeLocHandler}
                searchResults={searchResults}
                searchLocResults={Object.keys(searchLocResults).splice(0, 5)}
                selectSearchResult={selectSearchResult}
                setSearchParam={(value: string) => setSearchParams(value)}
                onSearchClick={onSearchClick}
                setSearchResults={setSearchResults}
                setInputVal={(value: string, event: any) => {
                  setInputValHandler(value, event.target.id);
                }}
                setLocParam={(value: string) => {
                  setInputParams((prevState: any) => ({
                    ...prevState,
                    location: value,
                  }));
                }}
                setSearchLocResults={setSearchLocResults}
                selectLocResult={selectLocResult}
                selectedCountry={
                  inputParam.country || inputParam.preSelectedCountry
                }
                countyList={countyList}
                isRCPortal={isRCPortal}
                isCategoryPage={isCategoryPage}
                setSelectedCountry={(value: string) => {
                  setInputParams((prevState: any) => ({
                    ...prevState,
                    country: value,
                  }));
                }}
                isSearchFilter
              />
              {FiltersType()}
              {remoteWorkTips()}
            </StyledContainerMd>
          </>
        ) : (
          <SearchFilter
            isSearchButtonPressed={isSearchButtonPressed}
            locParam={inputParam.location}
            searchParam={inputParam.searchkeyword || inputParam.selectedKeyword}
            changeHandler={changeHandler}
            changeLocHandler={changeLocHandler}
            searchResults={searchResults}
            searchLocResults={Object.keys(searchLocResults).splice(0, 5)}
            selectSearchResult={selectSearchResult}
            setSearchParam={(value: string) => setSearchParams(value)}
            onSearchClick={onSearchClick}
            setSearchResults={setSearchResults}
            setInputVal={(value: string, event: any) => {
              setInputValHandler(value, event.target.id);
            }}
            setLocParam={(value: string) => {
              setInputParams((prevState: any) => ({
                ...prevState,
                location: value,
              }));
            }}
            setSearchLocResults={setSearchLocResults}
            selectLocResult={selectLocResult}
            selectedCountry={
              inputParam.country || inputParam.preSelectedCountry
            }
            countyList={countyList}
            isRCPortal={isRCPortal}
            setSelectedCountry={(value: string) => {
              setInputParams((prevState: any) => ({
                ...prevState,
                country: value,
              }));
            }}
            isSearchFilter
          />
        )}

        {deviceType !== 'mobile' && (isRCPortal ? null : toolTips())}
      </Wrapper>
      {!isRCPortal && remoteWorkTips()}

      {/* Dropable filters Desktop */}
      {!isRCPortal && FiltersType()}

      {deviceType === 'mobile' && toggleFlyer && (
        <FilterFlyer
          settoggleFlyer={handleFlyer}
          selectedFilters={selectedFilters}
          jobFilters={props.jobFilters}
          handeleFilterChange={handleFilterChange}
          handleCategoryFilter={handleCategoryFilter}
          filterQuery={filterQs}
          activeJob={activeJob}
          setActiveJob={setActiveJob}
        />
      )}

      {(props.pageLocation == 'locationPage' ||
        props.pageLocation == 'locationJobs') &&
        deviceType !== 'mobile' && (
          <>
            <WorkAnywhereWrap>
              {(props.showWorkAnywhereInUsCheckbox ||
                props.showWorkAnywhereInWorldCheckbox) && (
                <strong>Work Anywhere in:</strong>
              )}
              {props.showWorkAnywhereInUsCheckbox && (
                <WorkAnyCheckWrap>
                  <input
                    type="checkbox"
                    id="work-in-us"
                    className="show-content"
                    checked={inputParam.isWorkAnywhereInUsChecked}
                    onClick={() => onWorkAnywhereChange('us')}
                  />
                  <label htmlFor="work-in-us">U.S</label>
                </WorkAnyCheckWrap>
              )}
              {props.showWorkAnywhereInWorldCheckbox && (
                <WorkAnyCheckWrap>
                  <input
                    type="checkbox"
                    id="world"
                    className="show-content"
                    checked={inputParam.isWorkAnywhereInWorldChecked}
                    onClick={() => onWorkAnywhereChange('world')}
                  />
                  <label htmlFor="world">World</label>
                </WorkAnyCheckWrap>
              )}
            </WorkAnywhereWrap>
          </>
        )}
      {isRCPortal
        ? isRemoteJobsPage
          ? searchCommonFilterwrapper()
          : !isCategoryJobsPage && (
              <StyledContainerMd isPrimaryContainer={isRCPortal}>
                <SeacrhFilterFooter
                  isRCPortal={isRCPortal}
                  isgridCol={props.isOpenDetailJobId}
                  isPremiumUser={isPremiumUser}
                  isSearchPage={isSearchPage}
                  isLegitimate={isLegitimate}
                >
                  {searchCommonFilterwrapper()}
                </SeacrhFilterFooter>
              </StyledContainerMd>
            )
        : searchCommonFilterwrapper()}
    </>
  );
};

export default SearchTipsFilter;
