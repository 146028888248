import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const OverviewWrap = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 'auto' : '27.7rem')};
  ${device.mobileLg} {
    max-height: ${({ expanded }) => (expanded ? 'auto' : '23rem')};
  }
  overflow: hidden;
  p {
    line-height: 1.5;
  }
`;
export const AuthorDetails = styled.h5`
  margin-bottom: 1.2rem;
  ${({ theme: { OverviewStyle } }) =>
    OverviewStyle &&
    css`
      color: ${OverviewStyle.Colors.colordark};
    `}
`;
export const TextLightLabel = styled.span`
  ${({ theme: { OverviewStyle } }) =>
    OverviewStyle &&
    css`
      color: ${OverviewStyle.Colors.mutedtext};
    `}
`;
export const WebsiteWrap = styled.div`
  ${Mixin.VerticalCenter({ align: 'baseline' })}
  margin-top: 2rem;
  ${device.mobileLg} {
    ${Mixin.VerticalCenter({ direction: 'column', align: 'center' })}
  }
`;
export const HeadquarterWrap = styled.div`
  ${device.mobileLg} {
    text-align: center;
  }
`;
export const SocialIconWrapper = styled.div`
  a {
    margin: 0 0.4rem;
  }
  a > i:before {
    ${({ theme: { OverviewStyle } }) =>
      OverviewStyle &&
      css`
        font-size: ${OverviewStyle.FontSizes.quoteiconfs};
      `}
  }
`;
export const ReadToggle = styled.button<{ noTopMargin?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  ${({ theme: { OverviewStyle } }) =>
    OverviewStyle &&
    css`
      font-size: ${OverviewStyle.FontSizes.toggle};
      color: ${OverviewStyle.Colors.toggle};
      font-weight: 700;
    `}
  margin:${({ noTopMargin }) => (noTopMargin ? '0' : '1.6rem 0 0')};
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const ArrowIcon = styled.span<{ expanded: boolean }>`
  margin-left: 0.5rem;

  i {
    transform: ${({ expanded }) =>
      expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
    &:before {
      ${({ theme: { OverviewStyle } }) =>
        OverviewStyle &&
        css`
          font-size: ${OverviewStyle.FontSizes.arrowiconfs};
        `}
    }
  }
`;
export const DivisionHeading = styled.h3`
  margin: 0.8rem 0 1.6rem;
`;
export const DivisionLinkList = styled.div<{ mergeBorder?: boolean }>`
  ${Mixin.AllCenter({ justify: 'flex-start', wrap: 'wrap', align: 'baseline' })}
  margin: 0.8rem 0 2.2rem;
  ${({ mergeBorder }) =>
    mergeBorder &&
    css`
      ${device.mobileLg} {
        margin: 0;
        padding-bottom: 2.2rem;
      }
    `}
`;
export const DivisionLink = styled.a`
  ${({ theme: { OverviewStyle } }) =>
    OverviewStyle &&
    css`
      background-color: ${OverviewStyle.BgColor.divisionlinkbg};
      border: 0.1rem solid ${OverviewStyle.Colors.bordercolor1};
    `}
  padding: 0.8rem 1.6rem;
  border-radius: 0.15rem;
  margin: 0 1.6rem 0.4rem 0;
`;
export const TabLink = styled.button`
  ${({ theme: { OverviewStyle } }) =>
    OverviewStyle &&
    css`
      font-size: ${OverviewStyle.FontSizes.tablink};
    `}
`;

export const FlexibilityMobWrap = styled.div`
  margin-top: 2rem;
`;
