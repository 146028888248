import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const PageHead = styled.h1<{
  pageLocation?: string;
  imagePath?: string;
}>`
  position: relative;
  padding-left: ${({ pageLocation }) =>
    pageLocation === 'locationPage' ? '0' : '4.4rem'};

  ${({ imagePath }) =>
    imagePath &&
    !imagePath.includes('undefined') &&
    css`
      &:before {
        background: url(${imagePath}) no-repeat;
        content: '';
        background-size: contain;
        ${Mixin.Position({ value: '50% auto auto 0' })}
        transform: translateY(-50%);
        width: 3.2rem;
        height: 3.2rem;
      }
    `}

  ${device.mobileLg} {
    padding-top: 3rem;
  }
`;

export const DescWrap = styled.div<{ expanded: boolean }>`
  display: inline;
`;
export const OverviewWrap = styled.div<{ pageLocation?: string }>`
  margin-bottom: 0.4rem;
  padding: 1.6rem 0;
  ${({ pageLocation }) =>
    pageLocation === 'acmgm' &&
    css`
      display: inline;
    `}
`;
export const FAQWrap = styled.div`
  padding: 1.6rem 0;
  // As we are getting the complete data from the backend and showing it using dangerouslySetInnerHTML,
  //  I’m using the class here which is coming from the backend itself.

  ${device.mediumScreen} {
    ${({ theme: { CatgoryDetailStyle } }) =>
      CatgoryDetailStyle &&
      css`
        .font-weight-medium {
          font-weight: ${CatgoryDetailStyle.FontWeight.medium};
        }
      `}
  }
`;
export const FAQQues = styled.p`
  margin-bottom: 1.6rem;
  ${({ theme: { CatgoryDetailStyle } }) =>
    CatgoryDetailStyle &&
    css`
      font-weight: ${CatgoryDetailStyle.FontWeight.medium};
    `}
`;
export const BreadcrumbWrap = styled.div`
  margin-top: 1.6rem;
`;
