import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  isRCPortal?: boolean;
  isCategoryPage?: boolean;
}>`
  ${Mixin.VerticalCenter()};

  ${({ theme: { JobCardStyle }, isRCPortal }) =>
    JobCardStyle &&
    isRCPortal &&
    css`
      background-color: ${JobCardStyle?.BgColors?.searchFilterWrapper};
      padding: 2.4rem 0;
      margin-bottom: 1.6rem;
    `}
  ${({ isCategoryPage }) =>
    isCategoryPage &&
    css`
      margin-bottom: 2.2rem;
    `}
    ${device.mobileLg} {
    padding-top: 1.6rem;
  }
`;

export const TipsText = styled.span`
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      color: ${toolTipStyle.Colors.tipsText};
      font-size: ${toolTipStyle.FontSizes.tipsText};
      font-weight: ${toolTipStyle.FontWeight.tipsTitle};
    `}
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &::after {
    content: '';
    display: inline-block;
    margin-left: 0.25rem;
    vertical-align: 0.25rem;
    border-top: 0.4rem solid;
    border-right: 0.4rem solid transparent;
    border-bottom: 0;
    border-left: 0.4rem solid transparent;
  }
`;

export const TipsHeader = styled.h5`
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      color: ${toolTipStyle.Colors.tipsTitle};
      font-weight: ${toolTipStyle.FontWeight.tipsTitle};
    `}
  margin-left: 0.5rem;
`;

export const ListWrap = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const ListItem = styled.li`
  padding: 0.8rem;
`;

export const IconWrap = styled.span`
  margin: 0 0.5rem 0.5rem 0;
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      color: ${toolTipStyle.Colors.iconWrap};
      font-size: ${toolTipStyle.FontSizes.iconWrap};
    `}
`;

export const TipsTitle = styled.p`
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      font-weight: ${toolTipStyle.FontWeight.tipsTitle};
    `}
`;

export const TipsDesc = styled.p`
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SearchResultsWrapper = styled.div<{ isRCPortal?: boolean }>`
  margin: ${({ isRCPortal }) =>
    css`
      ${isRCPortal ? '0' : '1rem 0 0'}
    `};
  ${({ isRCPortal }) =>
    isRCPortal
      ? Mixin.AllCenter({
        align: 'flex-start',
        direction: 'column',
        gap: '0.8rem',
      })
      : Mixin.AllCenter({ justify: 'space-between' })};
`;

export const ResultHeading = styled.h2<{
  islocation?: boolean;
  isRCPortal?: boolean;
}>`
  margin-bottom: 0;
  position: relative;
  ${Mixin.VerticalCenter()};
  ${device.mobileLg} {
    ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
        font-size: ${toolTipStyle?.FontSizes?.resultHead};
      `}
  }
  ${({ isRCPortal, theme: { toolTipStyle } }) =>
    isRCPortal &&
    css`
      font-size: ${toolTipStyle?.FontSizes?.resultHeading};
      line-height: 2.4rem;
      font-family: ${toolTipStyle?.FontFamily?.resultHeading};
      font-weight: 400;
    `}
`;
export const SearchResultHeading = styled.h2<{ isRCPortal?: boolean }>`
  margin-bottom: 0;
  position: relative;
  ${Mixin.VerticalCenter()};
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      font-size: ${toolTipStyle?.FontSizes?.resultDeskHead};
      font-family: ${toolTipStyle?.FontFamily?.resultHeading};
      font-weight: 400;
    `}
  ${device.mobileLg} {
    ${({ theme: { toolTipStyle }, isRCPortal }) =>
    toolTipStyle &&
    css`
        ${isRCPortal
        ? css`
              font-size: ${toolTipStyle.FontSizes.resultHeading};
              line-height: 2.4rem;
            `
        : css`
              font-size: ${toolTipStyle.FontSizes.resultHead};
            `}
      `}
  }
`;
export const InfoIcon = styled.button`
  position: relative;
  padding: 0;
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      font-size: ${toolTipStyle.FontSizes.infoIcon};
      color: ${toolTipStyle.Colors.infoIcon};
      margin-left: 0.7rem;
      cursor: pointer;
    `}
`;

export const CountHeading = styled.h5`
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      color: ${toolTipStyle.Colors.count};
    `}
`;

export const ResultWrapper = styled.div`
  flex-grow: 1;
`;

export const SaveBtnWrap = styled.div<{
  isSavedStatus?: boolean;
  isRCPortal?: boolean;
}>`
  ${Mixin.VerticalCenter()}
  position: relative;
  .save_search {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ${({ isRCPortal }) =>
    !isRCPortal &&
    css`
        :disabled {
          :hover {
            background-color: inherit;
            ${({ theme: { toolTipStyle } }) =>
        toolTipStyle &&
        css`
                background-color: ${toolTipStyle.BgColors.saveLinkHoverBg};
              `}
          }
        }
      `}
  }
  .saved_search_drop {
    ${({ isRCPortal }) =>
    isRCPortal
      ? css`
            margin-left: 1rem;
          `
      : css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            :disabled {
              :hover {
                ${({ theme: { toolTipStyle } }) =>
          toolTipStyle &&
          css`
                    color: ${toolTipStyle.Colors.saveSearchDrop};
                    background-color: ${toolTipStyle.BgColors
              .saveLinkHoverDropBg};
                  `}
              }
            }
          `}
  }
  .saved_search_drop,
  .save_search {
    :disabled {
      opacity: 0.6;
      cursor: default;
      text-decoration: none;
      color: ${({ isRCPortal }) => isRCPortal && 'inherit'};
      pointer-events: ${({ isRCPortal }) => isRCPortal && 'none'};
    }
  }
  ${({ isRCPortal, theme: { toolTipStyle } }) =>
    isRCPortal &&
    css`
      .show-content {
        background: transparent;
        border: 0;
        color: ${toolTipStyle.Colors.saveBtnWrapText};
        padding: 0;
        border-radius: 0;
        font-size: ${toolTipStyle.FontSizes.saveBtnWrapText};
        font-weight: 600;
        min-width: auto;
        color: ${toolTipStyle.Colors.saveBtnWrapText};
        > .fa-heart {
          display: none;
        }
        &:hover {
          background: transparent;
          color: ${toolTipStyle.Colors.saveBtnWrapText};
        }
        &.save_search {
          cursor: auto;
        }
      }
    `}
`;

export const SavePopup = styled.div<{ isRCPortal?: boolean }>`
  ${Mixin.Position({ value: '3.2rem 11.7rem auto' })};
  min-width: 16rem;
  padding: 0.8rem 0;
  z-index: 9;
  width: ${({ isRCPortal }) => (isRCPortal ? '20rem' : ' 17.7rem')};
  border-radius: 0.4rem;
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      background-color: ${toolTipStyle.BgColors.popupBg};
      border: 0.1rem solid ${toolTipStyle.BdrColors.popupBrdr};
    `}
  ${device.tabletMd} {
    inset: 3.2rem auto auto -4.3rem;
    padding: 0.7rem 0;
  }
`;

export const SaveLinks = styled.button`
  display: inline-block;
  padding: 0.3rem 1.5rem;
  width: 100%;
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      font-size: ${toolTipStyle.FontSizes.savedSearchMenu};
      color: ${toolTipStyle.Colors.saveLinkHover};
      &:hover,
      &:focus {
        background-color: ${toolTipStyle.BgColors.saveLinkHover};
        text-decoration: underline;
      }
    `}
`;

export const HeadingWrapper = styled.div<{
  islocation?: boolean;
  isRCPortal?: boolean;
}>`
  ${Mixin.AllCenter({ justify: 'flex-start' })}

  margin: ${({ isRCPortal }) => (isRCPortal ? '1.2rem 0 0' : '1rem 0 0')};
  ${({ islocation }) =>
    islocation &&
    css`
      ${device.mobileLg} {
        margin: 1rem 1rem 0.8rem 0;
      }
    `}
`;
export const WorkAnywhereWrap = styled.div`
  display: flex;
  margin: 1rem 0;
`;

export const WorkAnyCheckWrap = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 1rem;

  input {
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0.6rem;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.4rem;
    ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
        border: 0.1rem solid ${toolTipStyle.Colors.checkWrapInput};
      `}
  }
`;

export const RemoteWorkTip = styled.div`
  padding: 1.6rem;
  margin: 0.8rem 0 1.6rem;
  border-radius: 0.4rem;
  position: relative;

  ${device.mediumScreen} {
    max-width: 90%;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 55%;
    margin-left: -1rem;
    margin-top: -1rem;
  }

  &::before {
    top: -0.1rem;
    border: 1rem solid transparent;
    border-top: 0;
  }

  &::after {
    top: 0;
    border: 1rem solid transparent;
    border-top: 0;
  }
  ${({ theme: { toolTipStyle } }) =>
    toolTipStyle &&
    css`
      background: ${toolTipStyle.BgColors.remoteWrokTipBg};
      border: 0.1rem solid ${toolTipStyle.BdrColors.remoteWrokTipBrdr};
      &::before {
        border-bottom-color: ${toolTipStyle.BdrColors.remoteTipBrdrBefore};
      }

      &::after {
        border-bottom-color: ${toolTipStyle.BdrColors.remoteTipBrdrAfter};
      }
    `}
`;

export const JobTypeWrapper = styled.div<{ isRCPortal?: boolean }>`
  ${Mixin.HorizontalCenter({ justify: 'flex-end' })}
  width: fit-content;
  margin-left: auto;
  ${({ theme: { toolTipStyle }, isRCPortal }) =>
    toolTipStyle &&
    css`
      border: 0.1rem solid
        ${isRCPortal
        ? toolTipStyle?.BgColors?.filterButtonWrap
        : toolTipStyle?.BdrColors?.jobBtnWrap};
      ${isRCPortal &&
      css`
        max-height: 22px;
        align-items: center;
        margin-top: 1rem;
      `}
      border-radius:${isRCPortal ? '0.4rem' : '0.3rem 0 0 0.3rem'};
    `}
`;

export const FilterButtonWrap = styled.div<{
  isActive?: boolean;
  isRCPortal?: boolean;
}>`
  ${({ theme: { toolTipStyle }, isActive, isRCPortal }) =>
    toolTipStyle &&
    css`
      ${isRCPortal
        ? Mixin.VerticalCenter({ justify: 'center', align: 'center' })
        : null}
      .job-filter-btn {
        font-size: ${toolTipStyle.FontSizes.filterBtn};
        line-height: 0.5;
        border: none;
        background: ${isActive
        ? isRCPortal
          ? toolTipStyle?.BgColors?.filterButtonWrap
          : toolTipStyle.BgColors.jobBtnWrap
        : isRCPortal
          ? 'transparent'
          : toolTipStyle.BgColors.secWrap};
        color: ${isActive
        ? isRCPortal
          ? toolTipStyle?.Colors?.filterButtonWrap
          : toolTipStyle.Colors.jobBtnTxt
        : toolTipStyle.Colors.jobBtnTxt};
        ${isRCPortal
        ? null
        : css`
              box-shadow: ${isActive
            ? `inset 0 0.3rem 0.5rem ${toolTipStyle.Colors.filterShadow}`
            : ''};
            `}
        font-weight:${isRCPortal && '700'};
        min-width: ${isRCPortal && 'auto'};
        padding: ${isRCPortal ? '0.4rem 1rem' : '0.4rem 0.8rem'};
        ${isRCPortal
        ? css`
              height: 2rem;
              border-radius: 0;
            `
        : css`
              border-radius: 0.3rem 0 0 0.3rem;
            `}

        &:hover {
          text-decoration: none;
          background: ${isRCPortal
        ? toolTipStyle?.BgColors?.filterButtonWrap
        : toolTipStyle.BgColors.jobBtnWrap};
          color: ${isRCPortal
        ? toolTipStyle?.Colors?.filterButtonWrap
        : toolTipStyle.Colors.jobBtnTxt};
        }
        &:focus {
          outline-offset: ${isRCPortal ? '0.1rem' : '0.8rem'};
        }
      }
    `}
`;
export const FilterWrapper = styled.div<{
  isRCPortal?: boolean;
  isPremiumUser?: boolean;
  isSearchPage?: boolean;
  isLegitimate?: boolean;
  isOpenDetailJobId?: any;
}>`
  ${({ isRCPortal, isLegitimate }) =>
    isRCPortal
      ? css`
          ${isLegitimate
          ? css`
                ${Mixin.AllCenter({
            align: 'flex-end',
            justify: 'space-between',
          })};
                flex-wrap: wrap;
              `
          : css`
                ${Mixin.AllCenter({
            align: 'flex-end',
            justify: 'space-between',
          })};
              `}
        `
      : css`
          ${Mixin.AllCenter({ justify: 'space-between' })};
        `}
  margin: ${({
        isRCPortal,
        isOpenDetailJobId,
        isPremiumUser,
        isSearchPage,
        isLegitimate,
      }) =>
    isRCPortal
      ? `${isOpenDetailJobId
        ? 0
        : `${!isPremiumUser
          ? isLegitimate
            ? '0 auto 0.8rem'
            : '0'
          : `${isSearchPage
            ? '0.8rem 0 0 auto'
            : '0.8rem 1.5rem 0 0'
          }`
        }`
      }`
      : '1rem 0 2.4rem'};
  width: 100%;
  position: ${({ isRCPortal }) => isRCPortal && 'relative'};
`;

export const InnerWrap = styled.div<{ isRCPortal?: boolean }>`
  ${({ theme: { toolTipStyle }, isRCPortal }) =>
    toolTipStyle &&
    css`
      color: ${toolTipStyle?.Colors?.innerWrap};
      font-size: ${toolTipStyle?.FontSizes?.innerWrap};
      flex: ${isRCPortal && '0.8'};
      margin-right: 1rem;
    `}
  font-weight: 500;
`;

export const FilterWrap = styled.div`
  margin-top: 1.6rem;
  ${Mixin.AllCenter()};
`;

export const MbFilterWrap = styled.div<{
  isRCPortal?: boolean;
  noOfFiltersSelected?: any;
}>`
  ${Mixin.AllCenter({
  wrap: 'nowrap',
  gap: '0.7rem',
  justify: 'flex-start',
})};
  margin: 1.5rem 0 0;
  .mobilefilterbtn {
    ${({ isRCPortal, noOfFiltersSelected }) =>
    isRCPortal
      ? css`
            padding: 0.8rem 1rem;
            min-width: 11rem;
            ${({ theme: { JobCardStyle } }) =>
          JobCardStyle &&
          css`
                border-color: ${noOfFiltersSelected
              ? JobCardStyle.BdrColors.mbfilterwrapselected
              : JobCardStyle.BdrColors.mbfilterwrap};
                color: ${noOfFiltersSelected
              ? JobCardStyle.Colors.mbfilterwrapselected
              : JobCardStyle.Colors.mbfilterwrap};
              `}
          `
      : css`
            padding: 0.8rem 0.3rem;
            min-width: 7rem;
          `}
    .left-icon {
      font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 368px) {
    .mobilefilterbtn {
      min-width: 10rem;
    }
  }
`;

export const MbWorkAnywhereWrap = styled.div`
  ${Mixin.AllCenter({ wrap: 'wrap', justify: 'flex-start', gap: '0 0.4rem' })};
  padding-left: 0.7rem;
`;
export const SeacrhFilterFooter = styled.div<{
  isRCPortal?: boolean;
  isgridCol?: any;
  isPremiumUser?: boolean;
  isSearchPage?: boolean;
  isLegitimate?: boolean;
}>`
  ${({ isRCPortal, isgridCol, isPremiumUser, isSearchPage, isLegitimate }) =>
    isRCPortal &&
    css`
      ${!isPremiumUser
        ? Mixin.AllCenter({
          wrap: 'wrap',
          justify: 'space-between',
          align: 'flex-start',
          gap: '0 0.4rem',
          direction: 'column',
        })
        : Mixin.AllCenter({
          wrap: 'wrap',
          justify: 'space-between',
          align: 'flex-end',
          gap: '0 0.4rem',
        })};
      margin-bottom: 0.8rem;
      width: ${isgridCol == ''
        ? `${isSearchPage ? '73.8%' : '100%'}`
        : '100%'};
      ${device.mobileLg} {
        width: 100%;
      }
      ${!isPremiumUser &&
      css`
        ${isLegitimate
          ? css`
              padding: 0;
              margin: 0 0 0.8rem;
            `
          : css`
              padding: 0 0 0 1.5rem;
              margin: 0 -1.5rem 0.8rem;
            `}
      `}
    `}
`;
