import { checkIsFJFeatureAllowed, formatSalaryRange } from '@components/common';
import { IJobCardDeviceProps } from '@components/shared.types';
import { DateDifference } from '@utils/dateFormatter';
import { isRCPortal } from 'helper';
import LazyImage from 'helper/useLazyLoadImages';
import {
  AccoladeImg,
  AccoladeImgWrap,
  AccoladeName,
  AccoladeNameWrap,
  BottomWrap,
  CompnayNameInfoWrap,
  DeleteIcon,
  Featured,
  HeadWrap,
  IconWraps,
  JobAge,
  JobCompany,
  LocationSpan,
  MiddleWrap,
  MoreAccoladeIcon,
  MutedTitle,
  NewTag,
  Paragraph,
  SubAccoladeNameWrap,
  TagList,
  TagListWrap,
  TagsWrap,
  ThreeDots,
  Title,
  TitleWrap,
  Wrapper,
} from '../styled';

const JobCard = (props: IJobCardDeviceProps) => {
  const { openHostedJob } = props;

  const {
    featured,
    id,
    company,
    title,
    postedDate,
    allowedCandidateLocation,
    jobTypes,
    description,
    salaryRange,
    accolades,
    slug,
    isFreeJob,
  } = props.jobsData;

  const isAllowInRMC = checkIsFJFeatureAllowed();
  return (
    <>
      <Wrapper
        noSpace={props.isCompanyPage || props.isPastJobs}
        isNoImage={true}
        isActive={props.isActive}
        featured={featured}
        onClick={props.handleCardClick}
        onAuxClick={(e) =>
          openHostedJob ? openHostedJob(e, isAllowInRMC ? id : slug) : undefined
        }
        id={id}
        isRCPortal={isRCPortal}
      >
        <div>
          <>
            {featured && (
              <Featured id={'featured-' + id} isRCPortal={isRCPortal}>
                {isRCPortal ? (
                  <span className="star-icons">&#9733;</span>
                ) : (
                  <i className="fa fa-star"></i>
                )}{' '}
                Featured
              </Featured>
            )}

            <HeadWrap>
              <>
                <TitleWrap isRCPortal={isRCPortal}>
                  {props.isCompanyPage ? (
                    <>
                      {!props.isPastJobs ? (
                        <Title
                          id={'title-' + id}
                          href={`/job-details/${slug}/`}
                          rel={!isFreeJob ? 'nofollow' : undefined}
                          blurTest={true}
                        >
                          {props.hideJobTitle
                            ? 'Company Name Here Company Name Here Company Name Here'.substring(
                                0,
                                title.length
                              )
                            : title}
                        </Title>
                      ) : (
                        <MutedTitle
                          isPastJob={props.isPastJobs}
                          id={'subtitle-' + id}
                        >
                          {props.hideJobTitle
                            ? 'Company Name Here Company Name Here Company Name Here'.substring(
                                0,
                                title.length
                              )
                            : title}
                        </MutedTitle>
                      )}
                      {company && (
                        <JobCompany id={'company-name-' + id}>
                          {company.name}
                        </JobCompany>
                      )}
                    </>
                  ) : (
                    <>
                      <CompnayNameInfoWrap>
                        <Title
                          href={`/job-details/${slug}/`}
                          blurTest={false}
                          id={'job-name-' + id}
                          className="textWrap"
                          rel={!isFreeJob ? 'nofollow' : undefined}
                        >
                          {title}
                        </Title>
                        {DateDifference(String(postedDate)).daysDifference <
                          7 && (
                          <NewTag id={'new-' + id} isRCPortal={isRCPortal}>
                            New!
                          </NewTag>
                        )}
                      </CompnayNameInfoWrap>
                    </>
                  )}
                </TitleWrap>
              </>

              <JobAge id={'job-age-' + id}>
                <span id={'date-diff-' + id}>
                  {DateDifference(String(postedDate)).differenceResp}
                </span>
              </JobAge>
            </HeadWrap>

            <MiddleWrap isBadgeSuccess={isRCPortal}>
              <TagsWrap
                isPremiumUser={false}
                wrapTag={
                  (props.totalTagsCount > 3 && props.activeDetailId !== '') ||
                  props.totalTagsCount > 5
                }
              >
                {props.remoteOptionsFilter &&
                  props.remoteOptionsFilter.length > 0 && (
                    <TagList
                      isPremiumUser={false}
                      isTagName
                      className="tag-name"
                      key={'remoteoption' + id}
                      id={'remoteoption-' + 0 + '-' + id}
                      isBadgeSuccess={isRCPortal}
                    >
                      {props.remoteOptionsFilter[0]}
                    </TagList>
                  )}
                {props.jobSchedulesData &&
                  props.jobSchedulesData.length > 0 && (
                    <TagList
                      isPremiumUser={false}
                      isTagName
                      className="tag-name"
                      key={'jobschedule' + id}
                      id={'jobschedule-' + 0 + '-' + id}
                      isBadgeSuccess={isRCPortal}
                    >
                      {props.jobSchedulesData[0]}
                    </TagList>
                  )}

                {jobTypes && jobTypes.length > 0 && (
                  <TagList
                    isPremiumUser={false}
                    isTagName
                    className="tag-name"
                    key={'jobtypes' + id}
                    id={'jobTypes-' + 0 + '-' + id}
                    isBadgeSuccess={isRCPortal}
                  >
                    {jobTypes[0]}
                  </TagList>
                )}

                {salaryRange && salaryRange.length > 0 && (
                  <TagList
                    isPremiumUser={false}
                    isTagName
                    className="tag-name"
                    key={'tag' + 0}
                    id={'salartRange-' + 0 + '-' + id}
                    isBadgeSuccess={isRCPortal}
                  >
                    {formatSalaryRange(salaryRange)}
                  </TagList>
                )}

                {props.isSuggestedJobsPage && (
                  <DeleteIcon
                    id={'delete-btn-' + id}
                    onClick={(
                      e: React.MouseEvent<HTMLSpanElement, MouseEvent>
                    ) => {
                      props.deleteJob(e);
                    }}
                    className="fa fa-trash-can"
                  />
                )}
              </TagsWrap>
            </MiddleWrap>
            {allowedCandidateLocation && (
              <TagListWrap>
                <TagList
                  isPremiumUser={false}
                  isLocationFlag
                  className="allowed-location-flag"
                  key={'tag'}
                  id={'allowedlocation-flag-' + id}
                  isBadgeSuccess={isRCPortal}
                >
                  <IconWraps>
                    <span id={'location-info-' + id}>
                      <i
                        className="fa fa-location-dot"
                        title="Candidates need to be able to work in the City or State specified"
                      ></i>
                    </span>
                  </IconWraps>
                  {allowedCandidateLocation.map((tag: any, index: number) => (
                    <LocationSpan
                      className="allowed-location"
                      key={'tag' + index}
                      id={'allowedlocation-' + index + '-' + id}
                    >
                      {tag}
                      {allowedCandidateLocation.length - 1 != index ? ',' : ''}
                    </LocationSpan>
                  ))}
                </TagList>
              </TagListWrap>
            )}
            {!isRCPortal && (
              <Paragraph
                isMiniSummary={false}
                id={'description-' + id}
                dangerouslySetInnerHTML={{ __html: description }}
              ></Paragraph>
            )}

            {isAllowInRMC && Array.isArray(accolades) && accolades.length ? (
              <BottomWrap id={'accolades-' + id}>
                <AccoladeImgWrap>
                  {accolades.slice(0, 3).map((elem: any, index: number) => {
                    return (
                      <AccoladeImg key={index}>
                        <LazyImage
                          imgSrc={elem.url}
                          imgAlt={elem.name}
                          imgWidth={24}
                          imgHeight={24}
                        />
                      </AccoladeImg>
                    );
                  })}
                  {accolades.length > 3 && (
                    <MoreAccoladeIcon>
                      <ThreeDots>...</ThreeDots>
                    </MoreAccoladeIcon>
                  )}
                </AccoladeImgWrap>
                <AccoladeNameWrap>
                  {accolades.slice(0, 3).map((elem: any, index: number) => {
                    return (
                      <SubAccoladeNameWrap key={index}>
                        <AccoladeName key={index}>{elem.name}</AccoladeName>
                        {index !== accolades.slice(0, 3).length - 1 && ', '}
                      </SubAccoladeNameWrap>
                    );
                  })}
                </AccoladeNameWrap>
              </BottomWrap>
            ) : null}
          </>
        </div>
      </Wrapper>
    </>
  );
};

export default JobCard;
