import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  isCompanypage?: boolean;
  topSpace?: boolean;
}>`
  padding-bottom: 0.4rem;
  ${({ theme: { HeaderSectionStyle }, isCompanypage }) =>
    HeaderSectionStyle &&
    isCompanypage &&
    css`
      border-bottom: 0.1rem solid ${HeaderSectionStyle.BdrColors.wraperborder};
    `}
  ${device.mobileLg} {
    padding-bottom: 0;
  }
  ${({ topSpace }) =>
    topSpace &&
    css`
      padding-top: 1.6rem;
    `}
`;
export const DescriptionWrapper = styled.div`
  ${({ theme: { HeaderSectionStyle } }) =>
    HeaderSectionStyle &&
    css`
      margin: 0 0 0.8rem;
      border-bottom: 0.1rem solid ${HeaderSectionStyle.BdrColors.Description};
    `}
`;
export const ViewMoreBtn = styled.a<{
  isTextMore?: boolean;
  isTextless?: boolean;
}>`
  margin-bottom: 1.8rem;
  display: inline-block;
  position: relative;
  ${({ theme: { HeaderSectionStyle } }) =>
    HeaderSectionStyle &&
    css`
      font-size: ${HeaderSectionStyle?.FontSizes?.ViewMoreBtn};
    `}

  &::before {
    content: '';
    display: inline-block;
    border-width: 0.25rem 0.25rem 0px 0px;
    border-color: rgb(0, 110, 165);
    border-style: solid;
    height: 0.7rem;
    width: 0.7rem;
    transform: ${({ isTextMore }) =>
      isTextMore ? 'rotate(135deg)' : 'rotate(-45deg)'};
    position: absolute;
    right: -20px;
    bottom: ${({ isTextMore }) => (isTextMore ? '0.8rem' : '0.5rem')};
  }
`;
export const Description = styled.div<{ isCategoryPage?: boolean }>`
  margin-bottom: ${({ isCategoryPage }) => (isCategoryPage ? '0' : '1.2rem')};
  ${Mixin.VerticalCenter({ gap: '1.2rem' })};
  img {
    width: 4rem;
    height: 4rem;
  }
  p {
    ${({ theme: { HeaderSectionStyle }, isCategoryPage }) =>
      isCategoryPage
        ? css`
            margin: 0 0 1rem;
          `
        : css`
            margin: 0;
          `}
  }
`;
export const Heading = styled.h2<{ isCategoryPage?: boolean }>`
  ${({ theme: { HeaderSectionStyle }, isCategoryPage }) =>
    HeaderSectionStyle &&
    css`
      color: ${isCategoryPage
        ? HeaderSectionStyle.Colors.CategoryHeading
        : HeaderSectionStyle.Colors.title};
      ${isCategoryPage &&
      css`
        font-weight: 700;
        font-size: ${HeaderSectionStyle?.FontSizes?.CategoryHeading};
        font-family: ${HeaderSectionStyle?.FontFamily?.CategoryHeading};
      `}
    `}
  ${device.mobileLg} {
    ${({ theme: { HeaderSectionStyle }, isCategoryPage }) =>
      HeaderSectionStyle &&
      css`
        font-size: ${isCategoryPage
          ? HeaderSectionStyle?.FontSizes?.CategoryMobileHeading
          : HeaderSectionStyle.FontSizes.Heading};
      `}
    margin-top:${({ isCategoryPage }) => (isCategoryPage ? '1.6rem' : '1rem')};
    margin-bottom: ${({ isCategoryPage }) =>
      isCategoryPage ? '1.6rem' : null};
  }
  .badge {
    ${({ theme: { HeaderSectionStyle } }) =>
      HeaderSectionStyle &&
      css`
        font-size: ${HeaderSectionStyle.FontSizes.badge};
        background-color: ${HeaderSectionStyle.BgColors.badge};
        color: ${HeaderSectionStyle.Colors.badge};
        line-height: ${HeaderSectionStyle.LineHeights.badge};
      `}
    display: inline-block;
    padding: 0.7rem 1.4rem;
    border-radius: 0.4rem;
    vertical-align: super;
    margin-left: 0.8rem;
  }
`;
