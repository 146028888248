import styled from 'styled-components';

export const PopUpContent = styled.div`
  padding: 1.6rem 4rem 3.2rem;
`;
export const PopupFooter = styled.div`
  button {
    width: 100%;
  }
`;

export const CheckBoxWrap = styled.div`
  display: flex;
  margin-bottom: 0.8rem;
  input {
    margin: 0 0.5rem 0 0;
  }
`;
