import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const PaginatedWrap = styled.div`
  padding: 1.6rem 0 2rem;

  ${Mixin.AllCenter()};
  .pagination {
    padding: 0;
    margin: 0;
    list-style: none;

    ${Mixin.AllCenter({ gap: '0' })};
    li {
      a {
        padding: 0.8rem;
        margin-left: -0.1rem;
        display: block;
        text-transform: capitalize;
        cursor: pointer;

        ${({ theme: { PaginationStyle } }) =>
    PaginationStyle &&
    css`
            border: 0.1rem solid ${PaginationStyle.BdrColors.paginationLink};
            border-right: 0;
            &:focus {
              z-index: 3;
              color: ${PaginationStyle.BdrColors.paginationLinkFocus};
              background-color: ${PaginationStyle.BdrColors
        .paginationLinkHover};
              outline: 0;
              box-shadow: 0 0 0 0.25rem
                ${PaginationStyle.Colors.paginationLinkFocus};
            }
          `};

        ${device.mobileLg} {
          padding: 0.5rem;
          min-width: auto;
        }
      }

      &:last-child {
        a {
          ${({ theme: { PaginationStyle } }) =>
    PaginationStyle &&
    css`
              border-right: 0.1rem solid
                ${PaginationStyle.BdrColors.paginationLink};
            `}
        }
      }

      &.disabled,
      &.break-me {
        a {
          pointer-events: none;
          ${({ theme: { PaginationStyle } }) =>
    PaginationStyle &&
    css`
              color: ${PaginationStyle.Colors.linkDisable};
            `}
        }
      }

      &:first-child a {
        border-radius: 0.4rem 0 0 0.4rem;
      }

      &:last-child a {
        border-radius: 0 0.4rem 0.4rem 0;
      }

      &.active a {
        position: relative;
        z-index: 10;
        cursor: auto;

        ${({ theme: { PaginationStyle } }) =>
    PaginationStyle &&
    css`
            background-color: ${PaginationStyle.BgColors.paginationLinkActive};
            border-color: ${PaginationStyle.BdrColors.paginationLinkActive};
            color: ${PaginationStyle.Colors.paginationLinkActive};
          `}
        &:hover {
          text-decoration: none;
        }
      }

      &:not(.disabled, .active) a:hover {
        ${({ theme: { PaginationStyle } }) =>
    PaginationStyle &&
    css`
            background-color: ${PaginationStyle.BgColors.paginationLinkHover};
            border-color: ${PaginationStyle.BdrColors.paginationLinkHover};
            color: ${PaginationStyle.Colors.paginationLinkHover};
          `}
      }
    }
  }
`;
