import { isPortalContent } from 'helper';
import LazyImage from 'helper/useLazyLoadImages';
import { useEffect, useRef } from 'react';
import {
  Arrow,
  IconGuideList,
  ListItem,
  ListItemDesc,
  ListItemFlag,
  ListItemIcon,
  ListItemImgGlobal,
  PopoverBody,
  PopoverHeader,
  PopoverTitle,
  PopoverWrap,
} from './styled';

type Props = {
  toggleStatus?: boolean;
  mobTransform?: string;
  deviceType?: string;
};
const JobGuidePopover = (props: Props) => {
  // Refs to store references to modal and arrow elements
  const modalRef: any = useRef(null);
  const modalArrow: any = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      // Your custom logic to be executed on scroll
      // Get the position of the modal
      const modalPosition = modalRef.current.getBoundingClientRect();

      let desktopView = window.innerWidth > 992;
      if (modalRef.current && props.toggleStatus && desktopView) {
        if (modalPosition.top < 190) {
          setTimeout(() => {
            modalRef.current.style.inset = '3.2rem auto auto 0';
            modalRef.current.style.transform = 'translate(-20%,0%)';
            modalArrow.current.style.inset = '2.4rem 2% auto auto';
            modalArrow.current.style.transform = 'rotate(90deg)';
          }, 200);
        } else {
          setTimeout(() => {
            modalRef.current.style.inset = 'auto auto auto 0';
            modalRef.current.style.transform = 'translate(5%,-25%)';
            modalArrow.current.style.inset = '30% auto auto 2.2rem';
            modalArrow.current.style.transform = 'none';
          }, 200);
        }
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props.toggleStatus]);
  return (
    <>
      <Arrow ref={modalArrow} toggleStatus={props.toggleStatus} />
      <PopoverWrap
        ref={modalRef}
        role="tooltip"
        toggleStatus={props.toggleStatus}
        mobTransform={props.mobTransform}
      >
        <PopoverTitle>Job Icon Guide</PopoverTitle>
        <PopoverBody>
          <PopoverHeader>Job is open to:</PopoverHeader>
          <IconGuideList>
            <ListItem>
              <ListItemImgGlobal>
                <LazyImage
                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/flagIcons/icon-everywhere.png`}
                  imgAlt={'everywhere icon'}
                  imgWidth={16}
                  imgHeight={16}
                />
              </ListItemImgGlobal>
              Candidates Everywhere
            </ListItem>
            <ListItem>
              <ListItemFlag>
                <LazyImage
                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/flagIcons/icon-usa.png`}
                  imgAlt={'United States icon'}
                  imgWidth={20}
                  imgHeight={16}
                />
              </ListItemFlag>
              Candidates in US
            </ListItem>
            <ListItem>
              <ListItemFlag>
                <LazyImage
                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/flagIcons/australia.png`}
                  imgAlt={'Australia icon'}
                  imgWidth={20}
                  imgHeight={16}
                />
              </ListItemFlag>
              Australia Candidates
            </ListItem>
            <ListItem>
              <ListItemFlag>
                <LazyImage
                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/flagIcons/icon-canada.png`}
                  imgAlt={'Canada icon'}
                  imgWidth={20}
                  imgHeight={16}
                />
              </ListItemFlag>
              Canada Candidates
            </ListItem>
            <ListItem>
              <ListItemFlag>
                {' '}
                <LazyImage
                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/flagIcons/india.png`}
                  imgAlt={'India icon'}
                  imgWidth={20}
                  imgHeight={16}
                />
              </ListItemFlag>
              India Candidates
            </ListItem>
            <ListItem>
              <ListItemFlag>
                {' '}
                <LazyImage
                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/icons/flagIcons/uk.png`}
                  imgAlt={'UK icon'}
                  imgWidth={20}
                  imgHeight={16}
                />
              </ListItemFlag>
              UK Candidates
            </ListItem>
          </IconGuideList>
          <PopoverHeader>Job is for local candidate of:</PopoverHeader>
          <IconGuideList>
            <ListItem>
              <ListItemIcon
                className="fa fa-map-marker"
                aria-hidden="true"
              ></ListItemIcon>
              a Certain City or State
            </ListItem>
          </IconGuideList>
          <PopoverHeader>{isPortalContent} logos:</PopoverHeader>
          <IconGuideList flex>
            <ListItem>
              <ListItemFlag>
                {' '}
                <LazyImage
                  imgSrc={`${process.env.NEXT_PUBLIC_CONTENT_URL}/images/fj-star.png`}
                  imgAlt={'star icon'}
                  imgWidth={24}
                  imgHeight={24}
                />
              </ListItemFlag>
              <ListItemDesc>
                Featured Companies are employers who have come directly to
                {isPortalContent}, been approved by our staff, and have directly
                posted their jobs to the {isPortalContent} site.
              </ListItemDesc>
            </ListItem>
          </IconGuideList>
        </PopoverBody>
      </PopoverWrap>
    </>
  );
};

export default JobGuidePopover;
