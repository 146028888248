import { CountryResponseProps } from '@components/shared.types';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { isRCPortal } from 'helper';
import { useRef } from 'react';
import { checkIsFJFeatureAllowed } from '..';
import { US_GLOBAL_LOCATION, searchStaticList } from '../constant';
import SearchInput from './SearchInput';
import { InputWrap, SearchBtn, SearchWrap, Select, Styledform } from './styled';

interface Props {
  selectedCountry?: string;
  searchParam: string;
  locParam: string;
  type?: string;
  location?: string;
  isMobile?: boolean;
  isHeader?: boolean;
  searchResults?: any;
  searchLocResults?: string[];
  changeHandler: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  changeLocHandler: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  selectSearchResult: (_e: React.SyntheticEvent, _result: string) => void;
  selectLocResult: (_e: React.SyntheticEvent, _result: string) => void;
  onSearchClick: (_e: React.SyntheticEvent) => void;
  selectedIndex?: any;
  setSelectedIndex?: any;
  setSearchParam?: any;
  setSearchResults?: any;
  setLocParam?: any;
  setInputVal?: any;
  setSearchLocResults?: any;
  setSelectedCountry?: any;
  selectedLocIndex?: any;
  setSelectedLocIndex?: any;
  countyList?: CountryResponseProps[];
  isSearchFilter?: boolean;
  isSearchButtonPressed?: boolean;
  isRCPortal?: boolean;
  isRemoteJobsPage?: boolean;
  isCategoryPage?: boolean;
}

const SearchFilter = (props: Props) => {
  const searchJobRef = useRef<HTMLDivElement | null>(null);
  const searchLocationRef = useRef<HTMLDivElement | null>(null);

  const isMobileView = useMediaQuery(width.tabletSm);
  const isRmc = !checkIsFJFeatureAllowed();

  return (
    <Styledform
      onSubmit={(e: React.SyntheticEvent) => props.onSearchClick(e)}
      isHeader={props.isHeader}
      isRCPortal={props.isRCPortal}
      isRemoteJobsPage={props.isRemoteJobsPage}
      isCategoryPage={props.isCategoryPage}
    >
      <SearchWrap isHeader={props.isHeader}>
        <InputWrap isHeader={props.isHeader} isRCPortal={isRCPortal}>
          <SearchInput
            searchParam={props.searchParam}
            changeHandler={props.changeHandler}
            placeholder={
              isMobileView
                ? 'Search for Jobs...'
                : `Search by job title, keyword ${isRCPortal ? '' : ', etc.'}`
            }
            ariaLabel={
              isMobileView
                ? 'Search for Jobs...'
                : 'Search by job title, keyword, etc.'
            }
            bdrcurve={true}
            setInputVal={props.setInputVal}
            searchResults={props.searchResults}
            setSearchParam={props.setSearchParam}
            setSearchResults={props.setSearchResults}
            inputRef={searchJobRef}
            searchDataOnClick={props.onSearchClick}
            selectSearchResult={props.selectSearchResult}
            height={isMobileView ? '3.4rem' : '3.86rem'}
            borderRight
            id="search-jobs"
            isHeader={props.isHeader}
            isRCPortal={props.isRCPortal}
          />
        </InputWrap>
        <InputWrap isHeader={props.isHeader}>
          <SearchInput
            searchParam={props.locParam}
            changeHandler={props.changeLocHandler}
            setInputVal={props.setInputVal}
            placeholder={'Location'}
            ariaLabel="Location"
            searchResults={props.searchLocResults}
            setSearchParam={props.setLocParam}
            inputRef={searchLocationRef}
            setSearchResults={props.setSearchLocResults}
            selectSearchResult={props.selectLocResult}
            searchDataOnClick={props.onSearchClick}
            searchStaticList={isRmc ? [] : searchStaticList}
            height={isMobileView ? '3.4rem' : '3.86rem'}
            borderRight
            id="search-by-location"
            isHeader={props.isHeader}
            isRCPortal={props.isRCPortal}
            isborderRight={props.isRCPortal}
            isSelectCountry={
              props.isRCPortal &&
              props.selectedCountry &&
              !US_GLOBAL_LOCATION.US_LOC.includes(props.selectedCountry)
            }
          />
        </InputWrap>
        {props.selectedCountry &&
          !US_GLOBAL_LOCATION.US_LOC.includes(props.selectedCountry) && (
            <InputWrap isHeader={props.isHeader}>
              <Select
                id="select-country"
                borderRight
                onChange={(e) => props.setSelectedCountry(e.target.value)}
                isSearchFilter={props.isSearchFilter}
                isborderRight={props.isRCPortal}
                isRCPortal={isRCPortal}
              >
                <option value="">Select Country</option>
                <option value="hoa">
                  Anywhere (can be done from anywhere worldwide)
                </option>
                {props.countyList?.map(
                  (item: CountryResponseProps, index: number) => (
                    <option
                      selected={
                        props.selectedCountry?.toLowerCase() ===
                        item.countryName.toLowerCase()
                          ? true
                          : false
                      }
                      key={`country-${item.countryName}` + index}
                      value={item.countryName}
                    >
                      {item.countryName}
                    </option>
                  )
                )}
              </Select>
            </InputWrap>
          )}
        <SearchBtn
          id="submit-search"
          type="submit"
          aria-label="Search"
          aria-pressed={props.isSearchButtonPressed}
          isHeader={props.isHeader}
          isSearchFilter={props.isSearchFilter}
          className="show-content"
          isRCPortal={props.isRCPortal}
        >
          <i className="fa fa-search"></i>
        </SearchBtn>
      </SearchWrap>
    </Styledform>
  );
};

export default SearchFilter;
