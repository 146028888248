import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import { blob_img_path } from 'helper';
import styled, { css } from 'styled-components';

export const BannerWrapper = styled.div<{
  bannerImage?: string;
  bannerSubTile?: any;
}>`
  padding: 2.6rem 2rem;
  margin: 0;
  text-align: center;

  ${({ bannerImage, theme: { BannerSection } }) =>
    bannerImage &&
    css`
      background: ${BannerSection?.BgColors?.BannerWrapper}
        url(${blob_img_path + bannerImage}) center center no-repeat;
      background-size: cover;
    `}
  ${({ bannerSubTile }) =>
    bannerSubTile &&
    css`
      height: 30rem;
      ${Mixin.AllCenter()};
      flex-direction: column;
    `}
    ${device.mobileLg} {
    padding: 2.73rem 1.6rem;
    margin-bottom: 0;
  }
`;
export const ClientImageWrapper = styled.div`
  padding: 0.8rem 0;
  ${({ theme: { BannerSection } }) =>
    BannerSection &&
    css`
      background-color: ${BannerSection?.BgColors?.ClientImageWrapper};
    `}
  text-align: center;
`;
export const ClientImage = styled.img`
  filter: none;
`;
export const BannerHeading = styled.h1`
  line-height: 1.16;
  font-weight: 400;
  ${({ theme: { BannerSection } }) =>
    BannerSection &&
    css`
      font-size: ${BannerSection?.FontSizes?.BannerHeading};
      color: ${BannerSection?.Colors?.BannerHeading};
    `}

  margin-bottom: 0;
  text-shadow: -0.1rem -0.1rem 0 #646464, 0.1rem -0.1rem 0 #646464,
    -0.1rem 0.1rem 0 #646464, 0.1rem 0.1rem 0 #646464;

  ${device.mobileLg} {
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight:400;
    
  }
`;
export const BannerSubHeading = styled.div`
${({ theme: { BannerSection } }) =>
    BannerSection &&
    css`
      font-size: ${BannerSection?.FontSizes?.BannerSubHeading};
      color: ${BannerSection?.Colors?.BannerSubHeading};
    `}
  text-shadow: -0.1rem -0.1rem 0 #646464, 0.1rem -0.1rem 0 #646464,
    -0.1rem 0.1rem 0 #646464, 0.1rem 0.1rem 0 #646464;
`;
