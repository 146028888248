import { blob_img_path } from 'helper';
import Head from 'next/head';
import { BannerHeading, BannerSubHeading, BannerWrapper } from './styled';
interface Props {
  bannerImage?: string;
  bannerTile: string;
  bannerSubTile?: string;
  isPremiumUser?: boolean;
  isMobileView?: boolean;
}
const BannerSection = (props: Props) => {
  const { isMobileView } = props;
  const bannerButtonText = {
    url: props.isPremiumUser ? '/dashboard' : '/wizard/welcome',
    text: props.isPremiumUser ? 'My Dashboard' : 'Get Started',
  };

  return (
    <>
      <Head>
        {props.bannerImage && (
          <link
            rel="preload"
            href={`${blob_img_path}${props.bannerImage}`}
            as="image"
          />
        )}
      </Head>
      <BannerWrapper
        bannerImage={props.bannerImage}
        bannerSubTile={props.bannerSubTile}
      >
        {props.bannerTile && <BannerHeading>{props.bannerTile}</BannerHeading>}
        {props.bannerSubTile && (
          <BannerSubHeading>{props.bannerSubTile}</BannerSubHeading>
        )}
      </BannerWrapper>
      {/* {isMobileView && (
        <ClientImageWrapper>
          <ClientImage src={`${blob_img_path}banner-brand.svg`}></ClientImage>
        </ClientImageWrapper>
      )} */}
    </>
  );
};

export default BannerSection;
