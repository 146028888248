import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

type StoryProps = {
  pageLocation?: string;
};

export const SuccessStoriesWrap = styled.div<StoryProps>`
  ${({ pageLocation }) =>
    pageLocation === 'rjrc'
      ? css`
          text-align: left;
          padding: 0.9rem 0.4rem;
        `
      : css`
          text-align: center;
          padding: 0 0.4rem;
        `}
`;
export const AuthorName = styled.span<StoryProps>`
  ${({ pageLocation }) =>
    pageLocation === 'rjrc'
      ? css`
          ${({ theme: { OverviewStyle } }) =>
            OverviewStyle &&
            css`
              color: ${OverviewStyle.Colors.toggle};
            `}
        `
      : css`
          ${({ theme: { OverviewStyle } }) =>
            OverviewStyle &&
            css`
              color: ${OverviewStyle.Colors.colordark};
            `}
        `}
`;

export const StoryContent = styled.blockquote<StoryProps>`
  position: relative;
  ${({ pageLocation }) =>
    pageLocation === 'rjrc'
      ? css`
          margin: 0;
          padding: 4rem 0 0;
          &:before {
            ${({ theme: { OverviewStyle } }) =>
              OverviewStyle &&
              css`
                ${Mixin.Position({ value: '0 auto' })}
                content: ${OverviewStyle.Icons.quotesleft};
                font-size: ${OverviewStyle.FontSizes.quoteiconfs};
                color: ${OverviewStyle.Colors.quoteicon};
                font-weight: ${OverviewStyle.FontWeights.semibold};
                font-family: ${OverviewStyle.FontFamily.iconfontfamily};
              `}
          }
        `
      : css`
          margin: 1.2rem 0 0.5rem;
          padding: 0 2rem;
          text-align: left;
          &:after {
            ${Mixin.Position({ value: 'auto auto 0 auto' })}
            padding-left: 0.5rem;
            ${({ theme: { OverviewStyle } }) =>
              OverviewStyle &&
              css`
                content: ${OverviewStyle.Icons.quotesright};
                font-size: ${OverviewStyle.FontSizes.quoteiconmgm};
                color: ${OverviewStyle.Colors.quoteicon};
                font-weight: ${OverviewStyle.FontWeights.semibold};
                font-family: ${OverviewStyle.FontFamily.iconfontfamily};
              `}
          }
          &:before {
            ${({ theme: { OverviewStyle } }) =>
              OverviewStyle &&
              css`
                ${Mixin.Position({ value: '0 auto auto 0' })}
                content: ${OverviewStyle.Icons.quotesleft};
                font-size: ${OverviewStyle.FontSizes.quoteiconmgm};
                color: ${OverviewStyle.Colors.quoteicon};
                font-weight: ${OverviewStyle.FontWeights.semibold};
                font-family: ${OverviewStyle.FontFamily.iconfontfamily};
              `}
          }
        `};
`;
export const StoryAuthor = styled.p<StoryProps>`
  ${({ theme: { OverviewStyle } }) =>
    OverviewStyle &&
    css`
      border-bottom: 0.1rem solid ${OverviewStyle.Colors.storyAuthorShadow};
    `}
  &:last-child {
    border-bottom: 0;
  }
  ${({ pageLocation }) =>
    pageLocation === 'rjrc'
      ? css`
          margin: 0.8rem 0 1.6rem;
          padding-bottom: 1.6rem;
        `
      : css`
          text-align: center;
          margin: 0.8rem 0 0;
          padding-bottom: 2.5rem;
        `}
`;
export const StoryFooter = styled.div<StoryProps>`
  ${({ pageLocation }) =>
    pageLocation === 'rjrc'
      ? css`
          margin: 0 -2rem -1.3rem;
          padding: 0.8rem 0;
        `
      : css`
          ${({ theme: { OverviewStyle } }) =>
            OverviewStyle &&
            css`
              background-color: ${OverviewStyle.BgColor.divisionlinkbg};
            `}

          padding: 1.5rem;
          margin-bottom: 0.4rem;
        `}

  ${({ theme: { OverviewStyle } }) =>
    OverviewStyle &&
    css`
      border-top: 0.1rem solid ${OverviewStyle.Colors.bordercolor2};
    `}


  text-align: center;
  .chkout {
    &::before {
      ${({ theme: { OverviewStyle } }) =>
        OverviewStyle &&
        css`
          font-size: ${OverviewStyle.FontSizes.checkouticon};
        `}
    }
  }
`;

export const LocSucStoryWrap = styled.div<{ isForLocation?: boolean }>`
  padding: ${({ isForLocation }) => isForLocation && '2.4rem 0 0'};
  margin: ${({ isForLocation }) => isForLocation && '4.8rem 0 0'};
  .swiper-slide {
    padding: 2rem 4rem;
    &.swiper-slide-active {
      padding: ${({ isForLocation }) => isForLocation && '0 8.8rem'};
    }
    ${device.mobileLg} {
      &.swiper-slide-active {
        padding: ${({ isForLocation }) => isForLocation && '0 3rem'};
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 20%;
    ${({ theme: { OverviewStyle } }) =>
      OverviewStyle &&
      css`
        &::after {
          font-size: ${OverviewStyle.FontSizes.arrowBtn};
          font-weight: 700;
          color: ${OverviewStyle.Colors.arrowBtn};
        }
        &:hover {
          &::after {
            color: ${OverviewStyle.Colors.arrowBtnHover};
          }
        }
      `}
  }
  ${device.mobileLg} {
    margin: ${({ isForLocation }) => isForLocation && '0'};
    padding: ${({ isForLocation }) => isForLocation && '1rem 0 0'};
    .swiper-button-prev {
      left: -0.08rem;
    }
    .swiper-button-next {
      right: -0.08rem;
    }
  }
`;

export const LocationHiredAt = styled.strong`
  display: block;
  font-style: italic;
`;

export const LocaStoryAuthor = styled.p`
  margin-left: 15%;
  ${device.mobileLg} {
    margin-left: 4rem;
    padding-top: 1rem;
  }
`;

export const LocStoryBrowse = styled.div`
  margin: 2rem 0 1rem;
`;
