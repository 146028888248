import {
  getTotalSubcategoriesAndCategory,
  sanitizeFilters,
} from '@components/common';
import FilterCategoryLists from '@components/common/searchFilter/filter/filterCategoryList';
import {
  Button,
  OneSectionCollapsible,
  parseJSON,
} from '@license-admin/boldfjcomponents';
import { logErrorServer } from 'API/ApiHelpers';
import { ClickOption } from 'helper/eventTrackingUtils/constant';
import { useState } from 'react';
import {
  FilterListWrap,
  FilterText,
  FlyerBody,
  FlyerFooter,
  FlyerHeader,
  FlyerWrapper,
  MobSortFilterWrap,
} from './styled';

type Props = {
  jobFilters: Array<JobFilters>;
  selectedFilters: { [key: string]: Array<string> };
  settoggleFlyer?: any;
  filterQuery: string;
  handeleFilterChange: (
    _filterName: string,
    _filterCategory: string,
    _name: string
  ) => string;
  handleCategoryFilter: (
    _filter: any,
    _categoryId: string,
    _categoryType: 'Parent' | 'Child'
  ) => void;
  activeJob: string;
  setActiveJob: any;
};
type JobFilters = {
  name: string;
  categoryWithCount: CategoryWithCount;
  slug: string;
};

type CategoryWithCount = {
  [key: string]: number;
};

const SORT_BY_DATE_PARAM = 'sortbyposteddate';
const FilterFlyer = (props: Props) => {
  const { jobFilters } = props;
  const [activeSecName, setActiveSecName] = useState('Remote');
  const [sortByFilter, setSortByFilter] = useState(props.activeJob);
  let sanitizedFilter = jobFilters;

  if (jobFilters) {
    let filterData = parseJSON(
      JSON.stringify(jobFilters),
      logErrorServer,
      true,
      'FilterFlyer Component',
      'FJ_JOBS'
    );
    sanitizedFilter = sanitizeFilters(props.selectedFilters, filterData);
  }

  const removeQueryParams = () => {
    const url = window.location.href.split('?')[0];
    window.location.href = url;
  };

  const handleToggle = (name: string) => {
    if (activeSecName == name) {
      return setActiveSecName('');
    }
    return setActiveSecName(name);
  };

  const handleCloseClick = () => {
    props.settoggleFlyer(false);
  };
  const showJobs = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(props.filterQuery);
    if (sortByFilter === ClickOption.Date) {
      if (!urlParams.get(SORT_BY_DATE_PARAM)) {
        urlParams.append(SORT_BY_DATE_PARAM, 'true');
        if (urlParams.get('page')) urlParams.delete('page');
      }
    } else if (sortByFilter === ClickOption.Relevance) {
      if (urlParams.get(SORT_BY_DATE_PARAM)) {
        urlParams.delete(SORT_BY_DATE_PARAM);
        if (urlParams.get('page')) urlParams.delete('page');
      }
    }
    document.location.href =
      window.location.pathname + '?' + urlParams.toString();
  };

  const result = getTotalSubcategoriesAndCategory(
    props.selectedFilters.categories || [],
    jobFilters
  );

  return (
    <>
      <FlyerWrapper>
        <FlyerHeader>
          <Button
            text="Reset"
            buttonType="transparent"
            ButtonClass="reset_btn"
            clickHandler={removeQueryParams}
            id="flyerfilter-reset"
          />
          <FilterText>Filters</FilterText>
          <Button
            ButtonClass="flyerCross"
            icon="fa-solid fa-xmark"
            buttonSize="small"
            buttonType="transparent"
            isIconRight
            clickHandler={handleCloseClick}
            id="flyerfilter-close"
          />
        </FlyerHeader>
        <FlyerBody>
          {sanitizedFilter.map((item: any, index: number) => {
            return (
              <>
                <OneSectionCollapsible
                  id={`${index}`}
                  key={index}
                  hasSubCategory
                  isFilterFlyer
                  headerClass="account-accordion"
                  name={item.name.trim()}
                  handleToggle={handleToggle}
                  activeSecName={activeSecName}
                  autoHeight={
                    item.name === 'Career Level' || item.name === 'Title'
                  }
                >
                  <div>
                    {item.name}{' '}
                    {props.selectedFilters &&
                    item.slug === 'categories' &&
                    Number(result) > 0
                      ? `(${result})`
                      : props.selectedFilters[item.slug] &&
                        props.selectedFilters[item.slug].length > 0 &&
                        `(${props.selectedFilters[item.slug].length})`}
                  </div>
                  <FilterListWrap>
                    <FilterCategoryLists
                      categoryVal={item.category}
                      categoryData={item.categoryWithCount}
                      selectedFilters={props.selectedFilters}
                      changeHandler={props.handeleFilterChange}
                      handleCategoryFilter={props.handleCategoryFilter}
                      {...item}
                      selectedCategories={props.selectedFilters[item.slug]}
                      parentId={index}
                    />
                  </FilterListWrap>
                </OneSectionCollapsible>
              </>
            );
          })}
          {Array.isArray(props.selectedFilters?.searchkeyword) &&
            props.selectedFilters?.searchkeyword.length && (
              <OneSectionCollapsible
                hasSubCategory
                isFilterFlyer
                headerClass="account-accordion"
                handleToggle={handleToggle}
                name={'Sort Jobs by:'}
                activeSecName={activeSecName}
              >
                <div>Sort Jobs by:</div>
                <MobSortFilterWrap>
                  <Button
                    ButtonClass="sort-btn"
                    text="Relevance"
                    buttonType={
                      props.activeJob === 'relevance' ? 'primary' : 'secondary'
                    }
                    bdrRadius="0.4rem 0 0 0.4rem"
                    clickHandler={() => {
                      props.setActiveJob(ClickOption.Relevance);
                      setSortByFilter(ClickOption.Relevance);
                    }}
                    buttonSize="sm"
                    fullLength
                  ></Button>
                  <Button
                    text="Date"
                    clickHandler={() => {
                      props.setActiveJob(ClickOption.Date);
                      setSortByFilter(ClickOption.Date);
                    }}
                    buttonSize="sm"
                    buttonType={
                      props.activeJob === 'date' ? 'primary' : 'secondary'
                    }
                    bdrRadius="0 0.4rem 0.4rem 0"
                    ButtonClass="sort-btn"
                    fullLength
                  ></Button>
                </MobSortFilterWrap>
              </OneSectionCollapsible>
            )}
        </FlyerBody>
        <FlyerFooter>
          <Button
            text="Show Jobs"
            buttonType="tertiary"
            fullLength
            clickHandler={(e: React.SyntheticEvent) => showJobs(e)}
            id="show-jobs-btn"
            ButtonClass='show-jobs-btn'
          />
        </FlyerFooter>
      </FlyerWrapper>
    </>
  );
};

export default FilterFlyer;
