import { basePathRewrite } from '@components/common';
import { SuccessStory } from '@components/shared.types';
import { isPortalContent } from 'helper';
import { Fragment } from 'react';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  AuthorName,
  LocStoryBrowse,
  LocSucStoryWrap,
  LocaStoryAuthor,
  LocationHiredAt,
  StoryAuthor,
  StoryContent,
  StoryFooter,
  SuccessStoriesWrap,
} from './styled';

type pageLocation = {
  pageLocation?: string;
  CompanySuccessStories?: SuccessStory[];
  isForLocation?: boolean;
  role?: string;
};

const SuccessStorys = (props: pageLocation) => {
  return (
    <>
      {props.pageLocation == 'locationPage' ? (
        <LocSucStoryWrap
          isForLocation={props.isForLocation}
          id="loc-success-story"
          role={props.role}
        >
          <Swiper
            spaceBetween="10"
            slidesPerView={1}
            loop={true}
            allowTouchMove={false}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
          >
            <>
              {props?.CompanySuccessStories?.map((story) => (
                <Fragment key={story.content_id}>
                  <SwiperSlide>
                    <StoryContent pageLocation={props.pageLocation}>
                      {story.success_comment}
                    </StoryContent>
                    <LocaStoryAuthor>
                      <AuthorName pageLocation={props.pageLocation}>
                        {story.success_name} {story.success_initial}
                        {'., '}
                        {story.success_city ? `${story.success_city}, ` : ''}
                        {story.success_state ? `${story.success_state}, ` : ''}
                        {story.success_country}
                      </AuthorName>
                      <LocationHiredAt>
                        Hired at {story.success_company_name} as{' '}
                        {story.success_job_title}
                      </LocationHiredAt>
                    </LocaStoryAuthor>
                  </SwiperSlide>
                </Fragment>
              ))}
            </>
          </Swiper>
          <LocStoryBrowse>
            <a
              href={basePathRewrite('/reviews-success-stories')}
              target="_blank"
              rel="noreferrer"
            >
              Browse 1000s of Success Stories{' '}
              <i className="fa fa-angle-right fa-sm chkout"></i>
            </a>
          </LocStoryBrowse>
        </LocSucStoryWrap>
      ) : (
        <>
          <SuccessStoriesWrap
            pageLocation={props.pageLocation}
            id="comp-success-story"
            role="tabpanel"
          >
            {props?.CompanySuccessStories?.map((story) => (
              <Fragment key={story.content_id}>
                <StoryContent pageLocation={props.pageLocation}>
                  {`"${story.success_comment}"`}
                </StoryContent>
                <StoryAuthor pageLocation={props.pageLocation}>
                  <AuthorName pageLocation={props.pageLocation}>
                    {story.success_name} {story.success_initial} from{' '}
                    {story.success_city}, {story.success_state}{' '}
                    {story.success_country === 'United States'
                      ? ''
                      : story.success_country}
                  </AuthorName>
                  —{' '}
                  <strong>
                    Hired at {story.success_company_name} as{' '}
                    {story.success_job_title}
                  </strong>
                </StoryAuthor>
              </Fragment>
            ))}
          </SuccessStoriesWrap>
          <StoryFooter pageLocation={props.pageLocation}>
            <a
              href={basePathRewrite('/reviews-success-stories')}
              target="_blank"
              rel="noreferrer"
            >
              Check Out Thousands More {isPortalContent} Success Stories!&nbsp;
              <i className="fa fa-angle-right fa-sm chkout"></i>
            </a>
          </StoryFooter>
        </>
      )}
    </>
  );
};

export default SuccessStorys;
