import { FormCheck } from '@license-admin/boldfjcomponents';
import {
  Checkbox,
  DropCatLink,
  DropCatList,
  DropCatListItem,
} from '../filterBtnGroup/styled';

interface Props {
  selectedCategories: Array<string>;
  isFilterFlyer?: boolean;
  slug: string;
  categoryData?: any;
  categoryVal?: any;
  selectedFilters: any;
  changeHandler: (_slug: string, _category: string, _name: string) => void;
  handleCategoryFilter: (
    _filter: any,
    _categoryId: string,
    _categoryType: 'Parent' | 'Child'
  ) => void;
  parentId?: string;
  name: string;
}

const FilterCategoryLists = (props: Props) => {
  const filterDropdown = (filter: any) => {
    const isParentActive =
      props.selectedCategories &&
      props.selectedCategories.includes(JSON.stringify(filter.category.id));

    return (
      <DropCatList>
        {filter.subCategory.length > 0 &&
          filter.subCategory.map((filterSub: any, index: any) => (
            <DropCatListItem
              key={`subcat-${index}`}
              className="sub-cat"
              id={`subcat-${filterSub.name.replace(/\s+/g, '-')}-${
                props.parentId
              }`}
            >
              <DropCatLink
                onClick={() =>
                  props.handleCategoryFilter(
                    filter,
                    JSON.stringify(filterSub.id),
                    'Child'
                  )
                }
              >
                <Checkbox
                  type="checkbox"
                  checked={
                    isParentActive
                      ? isParentActive
                      : props.selectedCategories &&
                        props.selectedCategories.includes(
                          JSON.stringify(filterSub.id)
                        )
                  }
                  id={`checkbox-${filterSub.name.replace(/\s+/g, '-')}-${
                    props.parentId
                  }`}
                />
                {filterSub.name}
              </DropCatLink>
            </DropCatListItem>
          ))}
      </DropCatList>
    );
  };

  return (
    <>
      <DropCatList>
        {!props.categoryVal
          ? Object.entries(props.categoryData).map(([category, count]) => (
              <DropCatListItem key={category}>
                <DropCatLink
                  isFilterFlyer={props.isFilterFlyer}
                  onClick={() =>
                    props.changeHandler(props.slug, category, props.name)
                  }
                >
                  <>
                    <FormCheck
                      type="checkbox"
                      checked={
                        props.selectedCategories &&
                        props.selectedCategories.includes(category)
                      }
                      id={`${category.replace(/\s+/g, '-')}-${props.parentId}`}
                    />
                    {category}
                  </>
                </DropCatLink>
              </DropCatListItem>
            ))
          : props.categoryVal.map((filter: any, index: number) => (
              <DropCatListItem key={'cat' + index}>
                <DropCatLink
                  isFilterFlyer={props.isFilterFlyer}
                  onClick={() =>
                    props.handleCategoryFilter(
                      filter,
                      JSON.stringify(filter.category.id),
                      'Parent'
                    )
                  }
                >
                  <Checkbox
                    type="checkbox"
                    checked={
                      props.selectedCategories &&
                      props.selectedCategories.includes(
                        JSON.stringify(filter.category.id)
                      )
                    }
                    id={`checkbox-${filter.category.name.replace(
                      /\s+/g,
                      '-'
                    )}-${props.parentId}`}
                  />
                  {filter.category.name}
                </DropCatLink>
                {filterDropdown(filter)}
              </DropCatListItem>
            ))}
      </DropCatList>
    </>
  );
};

export default FilterCategoryLists;
