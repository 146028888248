import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

interface Props {
  color?: string;
  bgColor?: string;
  tooltipWidth?: string;
  arrowPosition?: string;
  hAlign?: string;
}

export const SecWrap = styled.div`
  position: relative;
`;

export const ToolTipBtn = styled.button`
  background: transparent;
  border: 0;
  white-space: nowrap;
`;

export const TipContents = styled.div<Props>`
  ${Mixin.Position({ value: '3.1rem 0 auto auto' })};
  border-radius: 0.35rem;
  padding: 2.4rem;
  z-index: 2;
  ${({ theme: { toolTipStyle }, bgColor, color, tooltipWidth }) =>
    toolTipStyle &&
    css`
      color: ${color || toolTipStyle.Colors.secWrap};
      background-color: ${bgColor || toolTipStyle.BgColors.secWrap};
      border: 0.1rem solid ${bgColor || toolTipStyle.BdrColors.secWrap};
      width: ${tooltipWidth ? tooltipWidth : '30rem'};
    `};

  &::before {
    content: '';
    margin-left: -0.5rem;
    border-style: solid;
    border-width: 0 1.42rem 1.25rem;
    border-color: transparent;
    ${Mixin.Position({ value: '-1.2rem 0.9rem auto auto' })};
    ${({ theme: { toolTipStyle }, bgColor }) =>
      toolTipStyle &&
      css`
        border-bottom-color: ${bgColor || toolTipStyle.BdrColors.secWrap};
      `};
  }
  &::after {
    content: '';
    margin-left: -0.5rem;
    border-style: solid;
    border-width: 0.15rem 1.35rem 1.35rem;
    border-color: transparent;
    ${Mixin.Position({ value: '-1.25rem 1rem auto auto' })};
    ${({ theme: { toolTipStyle }, bgColor }) =>
      toolTipStyle &&
      css`
        border-bottom-color: ${bgColor || toolTipStyle.BgColors.secWrap};
      `};
  }
`;

export const CloseButton = styled.button`
  ${Mixin.Position({ value: '0.8rem 1rem auto auto' })};
  background-color: transparent;
  border: 0;
  opacity: 0.8;
  padding: 0;
  cursor: pointer;
  ${({ theme: { toolTipStyle }, color }) =>
    toolTipStyle &&
    css`
      color: ${color || toolTipStyle.Colors.secWrap};
      font-family: ${toolTipStyle.FontFamily.closeIcon};
      font-weight: ${toolTipStyle.FontWeight.closeIcon};
      &::before {
        content: ${toolTipStyle.Icons.closeIcon};
      }
    `};
`;
